const MODULES_COLORS_TYPES = {
  Compromiso: '#006DEB',
  Implementación: '#008568',
  Medición: '#D33C5C'
}

const INPUT_TYPES = {
  'single-choice': 'radio',
  'multiple-choice': 'checkbox',
  text: 'text'
}

export {MODULES_COLORS_TYPES, INPUT_TYPES}