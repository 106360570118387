import React, {useState} from 'react'
import {StyledFooter} from './styles'
import {SmallText} from '../../atoms/SmallText'
import {CloudCheck, WinguIcon} from 'shared/assets/svgs'

const Footer = () => {
  return (
    <StyledFooter>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <SmallText color='white' margin='0 2rem' style={{display: 'flex', alignItems: 'center'}}>
            <span style={{margin: '0 0.5rem'}}><CloudCheck /></span> Guardado en insight
          </SmallText>
          <a 
            style={{textDecoration: 'underline', cursor: 'pointer', color: 'white', margin: '0 1rem'}} 
            href='https://bit.ly/Insight_tyc'
            target='_blank'
            rel="noreferrer"
          >
            Ley de datos personales
          </a>
          <a 
            style={{textDecoration: 'underline', cursor: 'pointer', color: 'white', margin: '0 1rem'}} 
            href='https://bit.ly/Insight_privacidad'
            target='_blank'
            rel="noreferrer"
          >
            Politicas de privacidad
          </a>

        </div>
        <div style={{margin: '0 2rem', display: 'flex', alignItems: 'center'}}>
          &copy; 2022. Todos los derechos reservados. Desarrollado por <span style={{margin: '0 0.5rem'}}><WinguIcon /></span>
        </div>
      </div>
    </StyledFooter>
  )
}



export {Footer}