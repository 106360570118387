import styled from 'styled-components'

const StyledInput = styled.input`
  display: block;

  box-sizing: ${props => props.boxSizing ? props.boxSizing : 'border-box'};

  width: ${props => props.width};
  border: ${props => props.border ? props.border : '1px solid rgba(46, 45, 90, 0.35)'};
  border-radius: 10px;
  color: ${props => props.color};

  padding: ${props => props.padding || '0.75rem'};

  background: ${props => props.bg};

  box-shadow: 1px 1px 30px 1px rgba(15, 17, 38, 0.05);

  margin: 1rem 0.25rem;

  accent-color: #6C3F98;

  ${props => (props.type === 'checkbox' || props.type === 'radio') && 'transform: scale(1.5)'}
`


export {StyledInput}