import React, {useState, useEffect, useMemo} from 'react'

import styled from 'styled-components'
import {ProgressBar} from './UI/molecules/Progress'
import {getFormsByCharacteristic} from './helpers'
import {RegularText, Title, Select} from 'UI/atoms'
import {TinyBarChart} from 'UI/molecules'
import {Spinner} from 'shared/assets/svgs'
import {useIndexFormularyById} from './hooks/useIndexFormularyById'

const IndexPage = ({forms}) => {
  const [formSelectedId, setFormSelectedId] = useState(forms?.forms[0]?.id)
  const form = useIndexFormularyById(formSelectedId)

  const handleFormSelected = ({target}) =>{
    setFormSelectedId(target.value);
  }

  const totalFormsByCountry = getFormsByCharacteristic(form?.data?.index?.organizationLocations)
  const totalFormsByIndustry = getFormsByCharacteristic(form?.data?.index?.organizationSectors)
  
  const calculateOrganizationsAmount = (organizations) => {
    return organizations.reduce((acc, el) => acc + el.n, 0)
  }
  
  return (
    <>
    {form.isLoading
      ? <Spinner />
      : <section style={{width: '100%'}}>
      <Title fontSize='lg' color='white' style={{display: 'flex', justifyContent: 'center', margin: '4rem'}}>
        Índice
      </Title>
      <div>
        <div style={{display:'flex', justifyContent:'center', alignItems:'center', width: 'fit-content'}}>
          <label htmlFor='years' style={{fontSize:'18px', fontWeight: 700, margin: '0 1rem 0 0'}}>Año:</label>
          <Select 
            name='years' 
            id='years' 
            defaultValue={forms?.forms[0]?.id} 
            onChange={handleFormSelected}
            style={{backgroundColor: 'transparent', color: 'white', fontSize: '18px', border: '1px solid #FFF'}}
          >
            {forms?.forms?.map(form => (
              <option 
                key={form.id} 
                value={form.id}
              >
                {form.publishedDate.slice(0, 10)}
              </option>
            ))}
          </Select>
        </div>
        <div>
          <Wrapper style={{display: 'flex'}}>
            <Dashboard>
              <div style={{display: 'block', textAlign: 'center', margin: '2.5rem'}}>
                <Score fontSize='40px'>
                  {calculateOrganizationsAmount(form?.data?.index?.organizationLocations)}
                </Score>
                <RegularText color='black'>
                  organizaciones participantes
                </RegularText>
                <TinyBarChart chartData={form?.data?.index?.organizationSizes} />
              </div>
            </Dashboard>
          </Wrapper>
          <Wrapper style={{display: 'flex'}}>
            <Dashboard>
              <div>
                {form?.data?.index?.organizationSectors?.map(sector => (
                  <div key={sector.id} style={{margin: '3rem'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <span style={{color: 'black'}}>{sector.name}</span>
                      <span style={{color: 'black'}}>{parseInt(sector.n)}</span>
                    </div>
                    <ProgressBar
                      bg='#D2FAF9'
                      completedBg='#00B9B4' 
                      amount={parseInt(sector.n)} 
                      total={totalFormsByIndustry}
                    />
                  </div>
                ))}
              </div>
            </Dashboard>
            <Dashboard>
              <div>
                {form?.data?.index?.organizationLocations?.map(location => (
                  <div key={location.name} style={{margin: '3rem'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <span style={{color: 'black'}}>{location.name}</span>
                      <span style={{color: 'black'}}>{parseInt(location.n)}</span>
                    </div>
                    <ProgressBar 
                      bg='#E4D0F7'
                      completedBg='#6C3F98'
                      amount={parseInt(location.n)} 
                      total={totalFormsByCountry}
                    />
                  </div>
                ))}
              </div>
            </Dashboard>
          </Wrapper>
        </div> 
      </div>
    </section>
    }
    </>
  )
}

const Dashboard = styled.div`
  display: flex;

  background: ${props => props.theme.colors.white};

  margin: 1rem;
  padding: 1rem 0;

  box-shadow: 1px 1px 30px 1px rgba(15, 17, 38, 0.05);
  border-radius: 12px;
`

const Score = styled.span`
  font-size: ${props => props.fontSize ? props.fontSize : props.theme.fontSizes.medium};
  font-weight: ${props => props.theme.fontWeight.semiBold};
  color: black;
`

const Wrapper = styled.div`
  display: flex;
`

export {IndexPage}

