import React from 'react'
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import {Login} from './pages/Login'

const UnauthenticatedApp = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path='login' element={<Login />}></Route>
          <Route path="*" element={<Navigate to="/login" />}/>
        </Routes>
      </Router>
    </>
  );
}

export {UnauthenticatedApp}
