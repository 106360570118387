import {useQuery} from "react-query"
import {client} from "utils/api-client"

const useLookupsWithOrganizations = (lookup) => {
    const {data} = useQuery({
      queryKey: lookup,
      queryFn: () =>
        client(
            `org-admin/lookups/organizations/${lookup}`, 
            // {token: user.token}
        ).then(data => data)
    })
    return data ?? []
}

const useLookups = (lookup) => {
    const {data} = useQuery({
      queryKey: lookup,
      queryFn: () =>
        client(
            `org-admin/lookups/${lookup}`, 
            // {token: user.token}
        ).then(data => data),
    })
    return data ?? []
}

export {useLookups, useLookupsWithOrganizations}