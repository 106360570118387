import React from 'react'

const CloudArrowDownload = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.1455 14.9582L13.0623 17.875L15.9791 14.9582" stroke="#FBFCFD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.0625 11V17.875" stroke="#FBFCFD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.2502 17.875H6.1877C5.50522 17.8743 4.83068 17.7285 4.20886 17.4472C3.58704 17.1659 3.03217 16.7556 2.58106 16.2435C2.12995 15.7313 1.79293 15.1291 1.59237 14.4767C1.39181 13.8244 1.3323 13.1368 1.41778 12.4597C1.50326 11.7826 1.73178 11.1314 2.08818 10.5494C2.44457 9.96737 2.92069 9.46779 3.48492 9.08383C4.04915 8.69987 4.6886 8.44032 5.36082 8.32239C6.03304 8.20446 6.72265 8.23086 7.38389 8.39983" stroke="#FBFCFD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.875 11C6.87511 9.91069 7.13406 8.83699 7.63051 7.86738C8.12697 6.89778 8.84672 6.06002 9.73045 5.42314C10.6142 4.78626 11.6366 4.36849 12.7135 4.20426C13.7903 4.04003 14.8908 4.13403 15.9242 4.47853C16.9576 4.82302 17.8944 5.40815 18.6573 6.18569C19.4202 6.96323 19.9874 7.91092 20.3123 8.95068C20.6371 9.99044 20.7102 11.0925 20.5255 12.1661C20.3409 13.2396 19.9038 14.2539 19.2502 15.1254" stroke="#FBFCFD" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export {CloudArrowDownload}

