import Modal from 'styled-react-modal'

const StyledModal = Modal.styled`
  position: relative;
  width: ${props => props.width};
  height: ${props => props.height};
  display: flex;
  align-items: ${props => props.alignItems};
  justify-content:  ${props => props.justifyCont};
  background-color: ${props => props.background || 'white'};
  border-radius: 20px;
`

export {StyledModal}