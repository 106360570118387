import styled from 'styled-components'

const StyledSubtitle = styled.h2`
  font-size: ${props => props.fontSize ? props.theme.fontSizes[props.fontSize] : props.theme.fontSizes.regular};
  font-weight: ${props => props.weight ? props.weight : props.theme.fontWeight.semiBold};
  color: ${props => props.color ? props.color : props.theme.colors.gray};
  text-align: ${props => props.textAlign};

  margin: ${props => props.margin};
`

export {StyledSubtitle}
