import styled from 'styled-components'

export const Heading = styled.h1`
font-size:30px; 
font-weight:700; 
color:white;
margin:auto;
padding-bottom:2rem
`
export const Button = styled.button`
font-family:"Readex Pro", serif;
background: none;
border: none;
border-bottom: ${props => props.selected ? '4px solid white' : ''};
font-weight: ${props => props.selected ? '700' : '400'};
font-size: 15px;
margin: 1rem;
margin-bottom: 0;
color: white;
letter-spacing: 2px;
padding-bottom: 1.5rem;
cursor:pointer;
`

export const DateContainer = styled.p`
font-family:"Readex Pro", serif;
font-size:14px;
align-self:end;
margin-bottom: 2rem;
`

// common


export const Select = styled.select`
padding: .8rem 1.2rem;
background: none;
border-radius: 10px;
color: white;
border: 1px solid white;
margin-left: 1rem;
text-align: left;
font-weight: 700;
min-width: 6.5rem;
height: 46px;

margin: 0.5rem;
`
export const DownloadButton = styled.button`
font-family: 'Reader pro', sans-serif;
color: white;
font-size: 15px;
background: none;
border: 1px solid #FBFCFD;
border-radius: 10px;
height: 46px;
padding: 1.2rem;
display: flex;
align-items: center;
justify-content: space-between;
margin: 1rem;
cursor: pointer;
& svg{
    margin-right: .5rem;

}
`