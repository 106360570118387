const ProgressCircleChart = ({
    strokeWidth = 15,
    background = "#D0D0CE",
    diameter = 250,
    orientation = "up",
    direction = "right",
    percentage,
    total
  }) => {
    const coordinateForCircle = diameter / 2;
    const radius = (diameter - 2 * strokeWidth) / 2;
    const circumference = Math.PI * radius;
  
    let percentageValue = percentage

    const semiCirclePercentage = percentageValue * (circumference / 100);
  
    let rotation;
    if (orientation === "down") {
      if (direction === "left") {
        rotation = "rotate(180deg) rotateY(180deg)";
      } else {
        rotation = "rotate(180deg)";
      }
    } else {
      if (direction === "right") {
        rotation = "rotateY(180deg)";
      }
    }
  
    return (
      <div className="semicircle-container" style={{ position: "relative" }}>
        <svg
          width={diameter}
          height={diameter / 2}
          style={{ transform: rotation, overflow: "hidden" }}
        >
            <defs>
                <linearGradient id="gradient">
                    <stop offset="0%" stopColor="#00ff62" />
                    <stop offset="100%" stopColor="#ff0000" />
                </linearGradient>
            </defs>
          <circle
            cx={coordinateForCircle}
            cy={coordinateForCircle}
            r={radius}
            fill="none"
            stroke={background}
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            style={{
              strokeDashoffset: circumference
            }}
          />
          <circle
            cx={coordinateForCircle}
            cy={coordinateForCircle}
            r={radius}
            fill="none"
            stroke='url(#gradient)'
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            style={{
              strokeDashoffset: semiCirclePercentage,
              transition:
                "stroke-dashoffset .3s ease 0s, stroke-dasharray .3s ease 0s, stroke .3s"
            }}
          />
        </svg>
      </div>
    );
  };

export {ProgressCircleChart}