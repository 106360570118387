import React from 'react'
import {StyledModal} from './styles'

const Modal = ({children, isOpen, toggleModal, width, height, background, justifyCont='center', alignItems='center', style}) => {
  return (
    <StyledModal
      isOpen={isOpen}
      onBackgroundClick={() => toggleModal(false)}
      onEscapeKeydown={() => toggleModal(false)}
      width={width}
      height={height}
      background={background}
      justifyCont={justifyCont}
      alignItems={alignItems}
      style={style}
    >
      {children}
    </StyledModal>
  )
}



export {Modal}