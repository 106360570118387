const Measurement = () => {
  return (
    <>
      <p>La <b>medición</b> y el <b>reporte</b> permiten comprender el estado de DEI y su progreso. Una organización que ha avanzado en la medición y reporte es aquella que integra sus objetivos de DEI a los objetivos estratégicos de la organización. Cuenta con métricas procesables que permiten la toma de decisiones y el aprendizaje organizacional desde la perspectiva de la DEI.</p> 

      <p><b>Nivel 5. Sustentable (13 a 16 puntos):</b> mide y reporta el impacto de la DEI en todas o en varias líneas de gestión en sus operaciones y utiliza diferentes indicadores de desempeño.</p>
                
      <p><b>Nivel 4. Progresivo (10 a 13 puntos):</b> mide el impacto de los programas de DEI a partir de diferentes indicadores y se reportan tanto interna, como externamente.</p>

      <p><b>Nivel 3. Compromiso (6 a 10 puntos):</b> la medición y reporte de la gestión de la DEI es relativamente nueva. Se utilizan medidas cualitativas y cuantitativas de DEI y, en muchos casos, de forma poco integrada con la analítica de la organización.</p>

      <p><b>Nivel 2. Conciencia (3 a 6 puntos):</b> se llevan a cabo mediciones y reporte de las actividades de manera puntual y no como parte integral de un tablero de comando general de gestión en DEI.</p>

      <p><b>Nivel 1. Compliance (0 a 3 puntos):</b> no se mide la gestión de la DEI. Por lo general, se hace seguimiento en el caso a caso y se mide la eficacia de acciones puntuales.</p>
    </>
  )
}

export {Measurement}