const MODULES_TYPES = {
    Compromiso: {
      name: 'Compromiso',
      color: '#006DEB',
      status: {
        finished: 'Revisar',
        start: 'Comenzar',
        continue: 'Continuar',
      }
    },
    Implementación: {
      name: 'Implementación',
      color: '#008568',
      status: {
        finished: 'Revisar',
        start: 'Comenzar',
        continue: 'Continuar',
      }
    },
    Medición: {
      name: 'Medición',
      color: '#D33C5C',
      status: {
        finished: 'Revisar',
        start: 'Comenzar',
        continue: 'Continuar',
      }
    }
  }

export {MODULES_TYPES}