import React from 'react'
import styled from 'styled-components'

const Caret = ({position, color='#65A5FF'}) => {
    return (
        <Wrapper position={position}>
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5 8.25L30.25 22L16.5 35.75" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </Wrapper>
    )
}

const Wrapper = styled.span`
    transform: ${props => props.position === 'left' ? 'rotate(180deg)' : null}; 
`

export {Caret}

