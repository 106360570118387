
import React from 'react'

const Burger = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.05261 16L26.9474 16" stroke="white" strokeWidth="2" strokeLinecap="round"strokeLinejoin="round"/>
        <path d="M12.6316 7.57895L26.9474 7.57895" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.6316 23.5789L26.9474 23.579" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    
    )
}

export {Burger}
