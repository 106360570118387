
import {useState, useReducer} from 'react'
import styled from 'styled-components'
import {Title, Select, Input, Label} from 'UI/atoms/index'
import {useGetData, useOrganizations} from 'hooks/organizations'
import {newOrganizationReducer} from '../../reducers/new-organization'
import {Button} from 'UI/atoms/index'
import {RegisterSelect} from 'pages/Login/molecules/Select'
import {useCommonLookups, useCommonLookupsWithOrganizations, useNewOrganizationRequest} from 'hooks/common'
import { ErrorMessage } from 'pages/Login/styles'

const defaultState =  {
  name: "",
  location: "",
  employeesNumber: null,
  type: "",
  representative: {
    name: "",
    contactNumber: "",
    emailAddress: "",
    position: "",
    pronoun: "",
    department: ""
  }
}

const Register = ({setOpenThanksModal, setOpenRegisterModal}) => {
  const [hasInterestConfirmation, setHasInterestConfirmation] = useState(false)
  const [hasTermsAccepted, setHasTermsAccepted] = useState(false)
  const [termsNotAcceptedError, setTermsNotAcceptedError] = useState(false)
  const departments = useCommonLookupsWithOrganizations('departments')
  const locations = useCommonLookups('locations')
  const sectors = useCommonLookupsWithOrganizations('sectors')
  const types = useCommonLookupsWithOrganizations('types')
  const pronouns = useCommonLookups('pronouns')

  const [state, dispatch] = useReducer(newOrganizationReducer, defaultState)
  const change = (field, value) => dispatch({type: 'mutate', field, payload: value})

  const {mutate: add} = useNewOrganizationRequest()

  const handleSubmit = (e) => {
    e.preventDefault()

    if(!hasTermsAccepted || !hasInterestConfirmation) {
      setTermsNotAcceptedError(true)
      return
    }
    
    add({...state})
    setOpenThanksModal(true)
    setOpenRegisterModal(false)
  }

  return (
    <form style={{margin: '2rem auto'}} onSubmit={handleSubmit}>
      <Title textAlign='center' margin='1rem 0' style={{display: 'flex', justifyContent: 'center'}}>Solicitar una cuenta</Title>
      <Wrapper>
        <div>
          <Input 
            name='organization-name' 
            type='text'
            onChange={(e) => change('name', e.target.value)}
            placeholder='Nombre de la organización'
            width='100%'
            required
          />
        </div>
        <div>
          <RegisterSelect
            name='types'
            onChange={(e) => change('type', e.target.value)}
            placeholder='Tipo de organización'
            border='1px solid rgba(46, 45, 90, 0.35)'
            boxShadow='1px 1px 30px 1px rgba(15, 17, 38, 0.05)'
            required
            options={types?.organizationTypes}
            selectedVal='Tipo de organización'
          />
        </div>
        <div>
          <RegisterSelect 
            name='pronounce' 
            border='1px solid rgba(46, 45, 90, 0.35)'
            onChange={(e) => change('representative', {...state?.representative, pronoun: e.target.value})}
            boxShadow='1px 1px 30px 1px rgba(15, 17, 38, 0.05)'
            options={pronouns?.pronouns}
            selectedVal='Pronombre'
            required
          />
        </div>
      </Wrapper>
  
      <Wrapper>
        <div>
          <RegisterSelect 
            name='locations'
            onChange={(e) => change('location', e.target.value)}
            border='1px solid rgba(46, 45, 90, 0.35)'
            boxShadow='1px 1px 30px 1px rgba(15, 17, 38, 0.05)'
            options={locations?.locations}
            selectedVal='País'
            required
          />
        </div>
        <div>
          <Input 
            name='employees-number' 
            type='number'
            onChange={(e) => change('employeesNumber', parseInt(e.target.value))}
            placeholder='Cantidad de empleados'
            width='100%'
            required
            max='500'
            min='1'
          />
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Input 
            name='interest' 
            id='interest'
            type='checkbox'
            onChange={() => setHasInterestConfirmation(prevState => !prevState)}
          />
          <Label 
            htmlFor='interest'
            color='black'
            style={{fontSize: '12px', margin: '0 0.5rem'}}
          >
            Manifiesto el interés de realizar el diagnóstico Insight de DEI por parte de mi organización.
          </Label>
        </div>
      </Wrapper>
      <Wrapper>
        <div>
          <Input 
            name='number' 
            type='number'
            onChange={(e) => change('representative', {...state?.representative, contactNumber: e.target.value})}
            placeholder='Teléfono'
            width='100%'
            required
          />
        </div>
        <div>
          <RegisterSelect
            name='sectors'
            onChange={(e) => change('sector', e.target.value)}
            placeholder='Sector'
            border='1px solid rgba(46, 45, 90, 0.35)'
            boxShadow='1px 1px 30px 1px rgba(15, 17, 38, 0.05)'
            required
            options={sectors?.organizationSectors}
            selectedVal='Sector'
          />
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Input 
            name='accept-conditions' 
            id='accept-conditions'
            type='checkbox'
            onChange={() => setHasTermsAccepted(prevState => !prevState)} />
          <Label 
            htmlFor='accept-conditions'
            color='black'
            style={{fontSize: '12px', margin: '0 0.5rem'}}
          >
            Acepto los términos y declaro haber leído y aceptado la política de datos y la política de privacidad.
          </Label>
        </div>
      </Wrapper>
      <Wrapper>
        <div>
          <Input 
            name='platform_responsible' 
            type='text'
            onChange={(e) => change('representative', {...state?.representative, name: e.target.value})}
            placeholder='Nombre responsable plataforma'
            width='100%'
            required
          />
        </div>
        <div>
          <Input 
            name='position' 
            type='text'
            placeholder='Posición'
            width='100%'
            required
            onChange={(e) => change('representative', {...state?.representative, position: e.target.value})}
          />
          </div>
          {termsNotAcceptedError && <div style={{fontSize: '12px', color: 'red'}}>Los términos y condiciones junto con el manifiesto de interés por parte de tu organización deben ser aceptados.</div>}

      </Wrapper>
  
      <Wrapper>
        <div>
          <Input 
            name='contact' 
            type='email'
            onChange={(e) => change('representative', {...state?.representative, emailAddress: e.target.value})}
            placeholder='Correo electrónico'
            width='100%'
            required
          />
        </div>
        <div>
          <RegisterSelect 
            name='department'
            onChange={(e) => change('representative', {...state?.representative, department: e.target.value})}
            border='1px solid rgba(46, 45, 90, 0.35)'
            boxShadow='1px 1px 30px 1px rgba(15, 17, 38, 0.05)'
            options={departments?.organizationDepartments}
            selectedVal='Área'
            required
          />
        </div>
      </Wrapper>

      <Button 
        background='#00B9B4' fontSize='sm' fontWeight='bold'
        color='white'
        borderRadius='10px'
        type='submit'
        style={{margin: '1rem auto', padding: '0.75rem 1.25rem'}}
      >
        Enviar solicitud
      </Button> 
    </form>
  )
}  

const Wrapper = styled.main`
  display: flex;
  > div {
    width: 22vw;
    margin: 0.25rem 2rem;
  }
`

export {Register}
