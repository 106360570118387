import { createGlobalStyle } from 'styled-components'


const GlobalStyle = createGlobalStyle`
  body { 
    margin: 0;
    font-family: 'Readex Pro', serif;
  }
  h1 {
    font-family: 'Readex Pro', serif;
    font-weight: 700;
  }
  button {
    font-family: 'Readex Pro', serif;
    font-weight: 700;
    font-size: 15px;
  }

` 

export default GlobalStyle