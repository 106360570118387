import styled from 'styled-components'

const StyledTitle = styled.h1`
font-size: ${props => props.theme.fontSizes[props.fontSize]};
font-weight: ${props => props.theme.fontWeight[props.fontWeight] || props.fontWeight};

text-align: ${props => props.textAlign};

color: ${props => props.color ? props.color : props.theme.colors.dark};

margin: ${props => props.margin};

display: flex;
`

export {StyledTitle}