import React from 'react';
import styled from 'styled-components';

const Button = ({children, background = 'transparent', fontSize = 'customBase', type, fontWeight, style, color, padding, borderRadius, margin, onClick}) => {
  return (
    <StyledButton
      onClick={onClick}
      background={background}
      fontSize={fontSize}
      style={style}
      color={color}
      fontWeight={fontWeight}
      type={type}
      borderRadius={borderRadius}
      padding={padding}
      margin={margin}
    >
        {children}
    </StyledButton>
  )
}

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: ${props => props.padding ? props.padding : ' 0.75rem 0'};
  margin: ${props => props.margin ? props.margin : ' 0.5rem 0'};

  color: ${props => props.color};
  background: ${props => props.background};

  font-size: ${props => props.theme.fontSizes[props.fontSize]};
  font-weight: ${props => props.fontWeight};
  text-align: center;

  border: none;
  border-radius: ${props => props.borderRadius};

  cursor: pointer;
`

export {Button}