import {useCallback} from 'react'
import {useAddAnswerByQuestionId} from "hooks/answers"

const usePostQuestionsByPage = (answers, refetch) => {
  const {mutate: add, isError} = useAddAnswerByQuestionId()
  const postAnswers = useCallback(() => {
    answers.forEach(answer => {
      add(answer)
    })
  }, [answers])

  return {
    postAnswers,
    isError
  }
}

export {usePostQuestionsByPage}