import styled from 'styled-components'

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  
  font-size: ${props => props.theme.fontSizes.base};
  color: ${props => props.color};
`

export {StyledLabel}