import {useQuery, useMutation} from "react-query"
import {client} from "utils/api-client"

const useNewOrganizationRequest = () =>  {
    return useMutation(
      data => 
        client(`common/organizations-registers`, {
          method: 'POST',
          data,
        })
    )
  }

const useCommonLookupsWithOrganizations = (lookup) => {
    const {data} = useQuery({
      queryKey: lookup,
      queryFn: () =>
        client(
            `common/lookups/organizations/${lookup}`, 
            // {token: user.token}
        ).then(data => data),
      refetchOnMount: false,
      refetchOnWindowFocus: false
    })
    return data ?? []
}

const useCommonLookups = (lookup) => {
    const {data} = useQuery({
      queryKey: lookup,
      queryFn: () =>
        client(
            `common/lookups/${lookup}`, 
            // {token: user.token}
        ).then(data => data),
      refetchOnMount: false,
      refetchOnWindowFocus: false
    })
    return data ?? []
}

export {useCommonLookups, useCommonLookupsWithOrganizations, useNewOrganizationRequest}