import React from 'react'
import {BarChart, Bar, XAxis, Tooltip, Cell} from 'recharts'
import {getFormsBySize} from 'pages/IndexPage/helpers'

const barColors = ["#6C3F98", "#E6148B", "#00B9B4"]

const TinyBarChart = ({chartData}) => {
  const tinyBarChartData = getFormsBySize(chartData)

  return (
    <BarChart width={375} height={170} data={tinyBarChartData}>
      <XAxis dataKey="Tamaño" />
      <Tooltip />
      <Bar barSize={30} dataKey="Cantidad" fill={'#E6148B'} radius={[25, 25, 0, 0]}>
        {
          tinyBarChartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={barColors[index]} />
          ))
        }
      </Bar>
    </BarChart>
  )
}

export {TinyBarChart}
   