import { useAuth } from "auth/context";
import styled from 'styled-components'
import React, { useState } from "react";
import {DropDownListContainer, DropDownList, ListItem} from './styles'
import { PlusCircle } from "shared/assets/svgs";
import { PowerIcon } from "shared/assets/svgs/SidebarIcons/Power";

const Dropdown = ({options, position}) => {
  const {logout} = useAuth()
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const onOptionClicked = value => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };

  return (
    <DropDownListContainer position={position}>
      <DropDownList>
        {options.map(option => (
          <ListItem 
            to={option.route} 
            onClick={() => onOptionClicked(option)} 
            key={Math.random()}
          >
            <span style={{margin: '0 0.5rem 0 0'}}>{option.icon}</span>
            {option.text}
          </ListItem>
        ))}
        {position === 'right'
          ? <Anchor
              href='https://www.insightdiversidad.com/'
              target='_blank'
            >
            <span style={{margin: '0 0.35rem 0 0'}}><PlusCircle /></span>
            Ver más
          </Anchor>
          : null
        }

        <Anchor
          onClick={() => logout('logout')}
        >
          <span style={{margin: '0 0.35rem 0 0'}}><PowerIcon /></span>
          Cerrar sesion
        </Anchor>
      </DropDownList>
    </DropDownListContainer>
  );
}

const Anchor = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  list-style: none;
  text-decoration: none;

  margin-bottom: 1rem;
  &:hover {
    color: ${props => props.theme.colors.violet};
  }
`

export {Dropdown}