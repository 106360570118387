import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
`

const EmptyBar = styled.div`
  width: 100px;
  height: 1vh;
  background: ${props => props.theme.colors.violet};

  border-radius: 30px;
`

const ProgressBar = styled.div`
  width: ${props => props.width};
  height: 1vh;
  position: absolute;
  top: 0;
  background: ${props => props.theme.colors.white};

  border-radius: 30px;
`

export {Wrapper, EmptyBar, ProgressBar}