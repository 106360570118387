import React from 'react'

const Chat = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 9.74995H15" stroke="#3D4852" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9 12.75H15" stroke="#3D4852" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.0315 18.3641L12.6431 20.6781C12.5765 20.7891 12.4822 20.8811 12.3695 20.9449C12.2568 21.0087 12.1295 21.0422 12 21.0422C11.8705 21.0422 11.7432 21.0087 11.6305 20.9449C11.5178 20.8811 11.4235 20.7891 11.3569 20.6781L9.96848 18.3641C9.90184 18.253 9.80758 18.1611 9.69488 18.0973C9.58218 18.0335 9.45487 18 9.32536 18H3.75C3.55109 18 3.36032 17.9209 3.21967 17.7803C3.07902 17.6396 3 17.4489 3 17.25V5.25C3 5.05109 3.07902 4.86032 3.21967 4.71967C3.36032 4.57902 3.55109 4.5 3.75 4.5H20.25C20.4489 4.5 20.6397 4.57902 20.7803 4.71967C20.921 4.86032 21 5.05109 21 5.25V17.25C21 17.4489 20.921 17.6397 20.7803 17.7803C20.6397 17.921 20.4489 18 20.25 18L14.6747 18C14.5451 18 14.4178 18.0335 14.3051 18.0973C14.1924 18.1611 14.0982 18.253 14.0315 18.3641V18.3641Z" stroke="#3D4852" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    )
}

export {Chat}