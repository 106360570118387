import {useQuery} from "react-query"
import {client} from "utils/api-client"

const useScatterChartBenchmark = (formId, paramsObj) => {
  const filteredParams = Object.entries(paramsObj).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})

  const esc = encodeURIComponent;
  const query = Object.keys(filteredParams)
    .map(k => esc(k) + '=' + esc(filteredParams[k]))
    .join('&')


  const {data} = useQuery({
    queryKey: ['scatter-chart-data', formId, filteredParams],
    queryFn: () =>
      client(
          `org-admin/organization/forms/${formId}/benchmark${query && '?'}${query}`
      ).then(data => data)
  },
  )
  return data ?? []
}

const useLineChartBenchmark = (paramsObj) => {
    const filteredParams = Object.entries(paramsObj).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})

    const esc = encodeURIComponent;
    const query = Object.keys(filteredParams)
      .map(k => esc(k) + '=' + esc(filteredParams[k]))
      .join('&')

    const {data} = useQuery({
      queryKey: ['charts-data', filteredParams],
      queryFn: () =>
        client(
            `org-admin/organization/forms-benchmark${query && '?'}${query}`
        ).then(data => data)
    },
    )
    return data ?? []
}

export {useLineChartBenchmark, useScatterChartBenchmark}