
import React from 'react'
import styled from 'styled-components'
import {Button} from 'UI/atoms/index'
import {Title, Subtitle, RegularText} from 'UI/atoms/index'
import {ArrowsClockWise, CloudCheck, Arrow} from 'shared/assets/svgs'
// import {useUpdateOrganizationById} from 'hooks/organizations'
import {useCreateFormAnswers} from 'hooks/formularies'

import {useNavigate} from 'react-router-dom'

const NotInitialized = ({organization, formularyId}) => {
  const navigate = useNavigate()
  const {mutate: createFormAnswers} = useCreateFormAnswers(formularyId)
  
  const startCuestionary = () => {
    createFormAnswers('in-progress')
  }

  return (
    <Wrapper>
      <div>
        <Title fontSize='2xxl' fontWeight='700' color='white' textAlign='center' style={{display: 'flex'}}>
          Hola, te damos la bienvenida al cuestionario Insight de DEI.
        </Title>
        <CallToAction>
          <Subtitle fontSize='customBase' fontWeight='700' color='white'>
            ¡Completa nuestro cuestionario para diagnosticar tu índice en DEI y mucho más!
          </Subtitle>
          <div style={{position: 'absolute', top: '6rem', bottom: '0', right: '7rem'}}>
            <Arrow />
          </div>
          <Button onClick={() => startCuestionary()}>
            <Baloon>
              Comenzar
            </Baloon>
          </Button>
        </CallToAction>
        <AdviceMessage>
          <div>
            <div>
              <ArrowsClockWise />
              <CloudCheck />
            </div>
            <RegularText margin='0 2rem'>
              La plataforma tiene autoguardado automático ante cualquier cambio que realices.
            </RegularText>
          </div>
        </AdviceMessage>
      </div>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 4rem;

  > div {
    margin-top: 4rem;
    margin: 0 auto;
  }
`

const CallToAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  margin-top: 4rem;

  width: 50%;

  > * {
    margin: 0 2rem;
    text-align: center;
  }
`

const AdviceMessage = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > div {
    display: flex;
    justify-content: space-between;
    border: 1px dotted white;
    border-radius: 20px;

    padding: 2rem;

    width: 25%;
  }
`

const Baloon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 125px;
  height: 125px;

  border-radius: 50%;

  background: #E0FA27;
  color: black;
`

export {NotInitialized}