import {useQuery} from "react-query"
import {client} from "utils/api-client"

const useIndexFormularyById = (formId = '1') => {
    const {data, isLoading} = useQuery({
      queryKey: ['formulary', formId],
      queryFn: () =>
        client(
            `org-admin/organization/forms/${formId}/index`, 
            // {token: user.token}
        ).then(data => data.form),
    }
    )
    return {data, isLoading} ?? {}
  }

export {useIndexFormularyById}