import React from 'react'
import {StyledSelect} from './styles'

const Select = ({name, defaultValue, selected, value, onChange, children, bg ='#FFFFFF', padding, border, width, margin, boxShadow, boxSizing, color, style, required}) => {
  return (
		<>
			<StyledSelect 
				name={name} 
				padding={padding}
				defaultValue={defaultValue}
				onChange={onChange}
				bg={bg}
				border={border}
				width={width}
				margin={margin}
				boxShadow={boxShadow}
				required={required}
				boxSizing={boxSizing}
				color={color}
				style={style}
				value={value}
				selected={selected}
			>
				{children}
			</StyledSelect>
		</>
	)
}

export {Select}