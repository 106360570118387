import {Title, Subtitle} from 'UI/atoms/index'
import {Button} from 'UI/atoms/index'

const ThanksForBeingPart = ({setOpenThanksModal}) => {
  return (
    <div style={{display: 'flex', justifyContent: 'center', height: '100%'}}>
      <div style={{textAlign: 'center', margin: '4rem'}}>
        <Title textAlign='center' margin='1rem 0' style={{display: 'flex', justifyContent: 'center'}}>
          ¡Gracias por ser parte de Insight!
        </Title>
        <Subtitle margin='2rem' weight='400'>
          Pronto recibirás un correo electrónico con las credenciales de acceso a Insight.
        </Subtitle>
        <Subtitle margin='2rem' weight='400'>
          Entre tanto, te invitamos a que explores nuestro sitio, descargues el Manual del Usuario, el Glosario y te vayas familiarizando con la plataforma Insight de DEI para ser parte del cambio.
        </Subtitle>
        <Button 
          background='#00B9B4' fontSize='sm' fontWeight='bold'
          color='white'
          borderRadius='10px'
          type='submit'
          style={{margin: '1rem auto', padding: '0.75rem 1.25rem'}}
          onClick={() => setOpenThanksModal(false)}
        >
          Cerrar
        </Button>
      </div>
    </div>
  )
}

export {ThanksForBeingPart}