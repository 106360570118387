const Implementation = () => {
    return (
      <>
        <p>La <b>implementación</b> sistémica de la estrategia en Diversidad, Equidad e Inclusión (DEI) es la forma en la que la empresa muestra su postura y propósito al respecto. Una implementación destacada en DEI es aquella que logra que la inclusión sea un ingrediente central en el diseño y ejecución de la estrategia organizacional.</p> 

        <p><b>Nivel 5. Sustentable (38 a 48 puntos):</b> la inclusión es parte integral de su cultura y sus integrantes son agentes de cambio.</p>
                  
        <p><b>Nivel 4. Progresivo (29 a 38 puntos):</b> la DEI se implementa sistemática e integralmente.</p>

        <p><b>Nivel 3. Compromiso (19 a 29 puntos):</b> La implementación se empieza a organizar en torno a planes y objetivos, incluyendo operaciones que no se tenían en cuenta y con mayor foco en la cultura inclusiva.</p>

        <p><b>Nivel 2. Conciencia (3 a 6 puntos):</b> la organización empieza a desarrollar acciones en DEI, pero estas aun no son sistemáticas.</p>

        <p><b>Nivel 1. Compliance (0 a 3 puntos):</b> no cuenta con planes concretos en DEI. Sus acciones en DEI cumplen con la regulación o son intervenciones puntuales y dispersas.</p>
      </>
    )
  }
  
  export {Implementation}