import React, {useState} from 'react';
import styled from 'styled-components';
import {ArrowDown, ArrowUp} from 'shared/assets/svgs/index';


// this should be a dummy component with a custom hook that handles state's logic but we're in a deadline brou
const Collapse = ({children}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  return (
    <Wrapper>
      <StyledCollapse
        isCollapsed={isCollapsed}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {children}
      </StyledCollapse>
      <span 
        onClick={() => setIsCollapsed(!isCollapsed)}
        style={{cursor: 'pointer'}}
      >
        {isCollapsed 
          ? <ArrowDown /> 
          : <ArrowUp />
        }  
      </span>
    </Wrapper>
  )
}

const StyledCollapse = styled.div`
    border-top: none;
    max-height: ${(props) => (props.isCollapsed ? "200px" : "100%")};
    overflow: hidden;
    transition: all 0.3s;
    
    cursor: pointer;

    z-index:2;

    // background: linear-gradient(0deg, rgba(73,64,64,1) 0%, rgba(108,63,152,1) 24%);

  `

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

export {Collapse}