import styled from 'styled-components'

const StyledInput = styled.input`
  display: block;
  
  width: ${props => props.width};
  border: none;
  border-radius: 10px;

  padding: 1rem;

  background: ${props => props.bg};

  &:focus-visible {
    outline: none;
  }
`

export {StyledInput}