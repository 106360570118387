
import {useState, useEffect} from 'react'

const useQuestionsByModules = (formulary) => {
    const [questionsByModules, setQuestionsByModules] = useState([])

    useEffect(() => {
      // change questions key name to modules name
      const {questions: modules, ...rest} = formulary ? formulary : {}
      const form = {modules, ...rest}
      
      const formattedModules = form?.modules?.map(question => {
        return {
          name: question.module,
          questions: [
            {
              ...question
            }
          ]
        }
      })
      
      const formWithQuestionsByModules = formattedModules?.reduce((acc, curr) => {
        //finding Index in the array where the module name matched
        const findIfModuleExist = acc.findIndex((module) => module.name === curr.name)
        // if in the new array no such object exist where
        // module matches then create a new object
        if (findIfModuleExist === -1) {
          const module = {
            name: curr.name,
            questions: [...curr.questions]
          }
          acc.push(module)
        } else {
          // if name module matches, then push the value 
          acc[findIfModuleExist].questions.push(...curr.questions)
        }
        return acc;
      }, []);
      
      setQuestionsByModules(formWithQuestionsByModules)
      return formWithQuestionsByModules

      }, [formulary, setQuestionsByModules])

  return {
    questionsByModules,
    setQuestionsByModules
  }
}                   
  
  export {useQuestionsByModules}