import React, {useState, useEffect} from 'react'
import {MODULES_COLORS_TYPES} from '../types';

const addBackgroundByModule = (module = {}) => {
  let backgrounds = document.getElementsByTagName("section");
  for(var i = 0; i < backgrounds.length; i++){
    backgrounds[i].parentElement.style.backgroundColor = MODULES_COLORS_TYPES[module.name]
  }

  return backgrounds
}

export {addBackgroundByModule}