import React from 'react'
import {StyledLabel} from './styles'

const Label = ({children, color, htmlFor, style}) => {
  return (
    <StyledLabel
      color={color}
      htmlFor={htmlFor}
      style={style}
    >
      {children}
    </StyledLabel>
  )
}


export {Label}