import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import {Sidebar, Header} from 'UI/molecules/index'
import {HomeFlow, Results, IndexPage, Recommendations, Profile, Cuestionary, CuestionaryFlow} from './pages/index'
import {useAuth} from './auth/context'

import {client} from 'utils/api-client'
import {useQuery} from 'react-query'
import {Footer} from 'UI/molecules/Footer'
import { Spinner } from 'shared/assets/svgs'

const AuthenticatedRoutes = ({organization, formulary, forms, logout}) => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to='/cuestionario'/>}/>
      <Route exact path='/cuestionario' element={<HomeFlow organization={organization} formulary={formulary} />}></Route>
      <Route exact path='/resultados' element={<Results organization={organization} forms={forms}  />}></Route>
      <Route exact path='/indice' element={<IndexPage forms={forms} />}></Route>
      {/* <Route exact path='/recomendaciones' element={<Recommendations organization={organization} />}></Route> */}
      <Route exact path='/perfil' element={<Profile organization={organization} />}></Route>
    </Routes>
  )
}

const AuthenticatedApp = () => {
  const {data: organization, status: orgStatus} = useQuery(['organization'], () => client('org-admin/organization'))
  const {data: forms, status: formsStatus} = useQuery('organization-forms', () => client('org-admin/organization/forms?order=desc&sortBy=publishedDate'))

  const {user, logout} = useAuth()
  const [toggleDropdown, setToggleDropdown] = useState('')

  const handleToggleDropdown = () => {
    if(toggleDropdown) setToggleDropdown('')
  }

  const [formulary, setForm] = useState({})
  useEffect(() => {
    if(forms) {
      client(`org-admin/organization/forms/${forms?.forms[0]?.id}`).then(res => setForm(res?.form))
    }

  }, [forms])

  return (
    <Router>
      {!forms 
        ? <Spinner isFull fill='white' />
        : <Wrapper onClick={() => handleToggleDropdown()}>
            <Sidebar user={user} logout={logout} setToggleDropdown={setToggleDropdown} toggleDropdown={toggleDropdown} />
            <section 
              style={{background: '#411968', minHeight: '100vh', color: 'white'}}
            >
              <Header organization={organization?.organization || {}} setToggleDropdown={setToggleDropdown} toggleDropdown={toggleDropdown} />
              <AuthenticatedRoutes 
                organization={organization?.organization || {}}
                formulary={formulary}
                forms={forms || {}}
              />         
              
              <Footer />
            </section>
          </Wrapper>  
      }
    </Router>
  );
}

const Wrapper = styled.section`
  max-width: 100vw;
  min-height: 100vh;
  display: flex;

  position: relative;
  
  background: ${props => props.theme.colors.violet};
`

export {AuthenticatedApp}
