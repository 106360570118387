import React from 'react'
import {Select} from 'UI/atoms/index'

const RegisterSelect = ({name, onChange, placeholder, border, boxShadow, selectedVal, options}) => {
  return (
    <div>
      <Select 
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        border={border}
        boxShadow={boxShadow}
        required
      >
        <option value="" disabled selected>{selectedVal}</option>
        {options?.map(option => (
          <option 
            key={option.id} 
            value={option.name}
          >
            {option.name}
          </option>
        ))}      
      </Select>
    </div>
  )
}

export {RegisterSelect}