import styled from 'styled-components'

export const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
`

export const SelectSection = styled.div`
margin: 2rem;
display: flex;
flex-direction: row;
justify-content: flex-start;
flex-wrap: wrap;
align-items: center;
width: 100%;
& select{
    min-width: 150px;
    max-width: 250px;
}
`