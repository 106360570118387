import React, {useState} from 'react';
import {Button} from 'UI/atoms/index'
import {Input , Title, RegularText} from 'UI/atoms/index'
import {Modal} from 'UI/molecules/index'
import {useAsync} from 'hooks/use-async'
import {Nodos, User} from 'shared/assets/svgs/LoginIcons/index'
import {Spinner, Lock, Eye} from 'shared/assets/svgs/index'
import {Wrapper, Form, InputWrapper, StyledHeader, SectionWrapper, ErrorMessage} from './styles'
import {useAuth} from 'auth/context'
import {Register} from './organisms/Register';
import {ThanksForBeingPart } from './organisms/ThanksForBeingPart';

import insight_logo from 'shared/images/insight_logo.png'

const errors = {
  'Unauthorized': 'Usuario o contraseña incorrectos'
}

const Login = () => {
  const {login} = useAuth()
  const [showPassword, setShowPassword] = useState(false)
  const [openRegisterModal, setOpenRegisterModal] = useState(false)
  const [openThanksModal, setOpenThanksModal] = useState(false)
  const passwordtype = showPassword ? 'text' : 'password'
  const {isLoading, run, isError} = useAsync()

  const handleSubmit = (e) => {
    e.preventDefault()
    const { username, password } = e.target.elements

    run(login({username: username.value, password: password.value}))
  }

  
  return (
    <>
      <Wrapper>
        <StyledHeader>
          <SectionWrapper>
            {/* <Nodos /> */}
            <img src={insight_logo} width='350' height='auto' />
            <Form 
              onSubmit={handleSubmit} 
            >
              <Title textAlign='center' style={{display: 'flex', justifyContent: 'center'}}>Iniciar sesión</Title>
              <InputWrapper>
                <div style={{position: 'absolute', bottom: '12px', left: '12px'}}>
                  <User /> 
                </div>
                <Input  
                  placeholder='Email'
                  type='text'
                  name='username' 
                  padding='1rem 2.5rem'
                  width='100%'
                />
              </InputWrapper>
              <InputWrapper>
                <div style={{position: 'absolute', bottom: '12px', left: '12px'}}>
                  <Lock /> 
                </div>
                <Input
                  placeholder='Password'
                  type={passwordtype}
                  name='password'
                  autoComplete='on'
                  padding='1rem 2.5rem'
                  width='100%'
                />
                <Button 
                  background='transparent'
                  type='button'
                  style={{position: 'absolute', bottom: '-7%', right: '12px'}}
                  onClick={() => setShowPassword(prevState => !prevState) }
                >
                  <Eye /> 
                </Button>
              </InputWrapper>   
              <Button 
                type='submit' 
                background='#00B9B4'
                fontSize='base'
                fontWeight='700'
                style={{width: '100%', padding: '0.75rem 0px', color: 'white', borderRadius: '10px'}}
              >
                {isLoading ? <Spinner fill='white' /> : 'Ingresar'}
              </Button>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1rem 0'}}>
                <RegularText color='#2E2D5A'>No tienes cuenta aún?</RegularText>
                <Button
                  type='button'
                  background='transparent'
                  color='#00B9B4'
                  fontSize='sm'
                  style={{textDecoration: 'underline', padding: '0.25rem 0', margin: '0 0.25rem'}}
                  onClick={() => setOpenRegisterModal(true)}
                >
                  solicitar
                </Button>
              </div>
              {isError ? <ErrorMessage>Revisa tus credenciales. Email o contraseña incorrectos.</ErrorMessage>: null}
            </Form>
          </SectionWrapper>
        </StyledHeader>
      </Wrapper>
      <Modal 
        isOpen={openRegisterModal}
        toggleModal={setOpenRegisterModal}
        width='fit-content'
        height='40rem'
        justifyCont='flex-start'
        alignItems='flex-start'
      >
        <Register 
          setOpenThanksModal={setOpenThanksModal} 
          setOpenRegisterModal={setOpenRegisterModal}
        />
      </Modal>

      <Modal 
        isOpen={openThanksModal}
        toggleModal={setOpenThanksModal}
        width='50rem'
        height='30rem'
        justifyCont='flex-start'
        alignItems='flex-start'
      >
        <ThanksForBeingPart 
          setOpenThanksModal={setOpenThanksModal} 
        />
      </Modal>

    </>
  )
}

export {Login}