import React from 'react'
import {Caret} from 'shared/assets/svgs'
import {Button} from 'UI/atoms/index'
import {useLocalStorage} from 'hooks/use-localstorage'

const Wizard = ({ children, postAnswers, setRadioAnswers, setCheckboxAnswers, moduleName, setIsModuleOpen}) => {
  const [activePageIndex, setActivePageIndex] = useLocalStorage('__module_pages__', {
    Medición: 0,
    Implementación: 0,
    Compromiso: 0
  })
  const pages = React.Children.toArray(children)
  const currentPage = pages[activePageIndex[moduleName]]

  const goNextPage = () => {
    postAnswers()
    if(activePageIndex[moduleName] + 1 === pages.length) {
      setIsModuleOpen(false)
      return
    }
    setActivePageIndex(prevState => ({
      ...prevState,
      [moduleName]: prevState[moduleName] + 1
    }))
    setRadioAnswers([])
    setCheckboxAnswers([])
  };

  const goPrevPage = () => {
    postAnswers()
    setActivePageIndex(prevState => ({
      ...prevState,
      [moduleName]: prevState[moduleName] - 1
    }))
    setRadioAnswers([])
    setCheckboxAnswers([])
  };

  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      {activePageIndex[moduleName] > 0 
        ? <Button 
            background='white'
            borderRadius='100px'
            padding='0.75rem'
            onClick={() => goPrevPage()}
          >
            <Caret position='left' />
          </Button>
        : null
      }        
      {currentPage}      
      <Button 
        background='white'
        padding='0.75rem'
        borderRadius='100px'
        onClick={() => goNextPage()}
      >
        <Caret />
      </Button>
    </div>
  )
}

export {Wizard}
