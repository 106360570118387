import React from 'react'
import {StyledSubtitle} from './styles'

const Subtitle = ({children, margin, weight = '500', fontSize, color, textAlign}) => {
  return (
    <StyledSubtitle margin={margin} weight={weight} fontSize={fontSize} color={color} textAlign={textAlign}>
      {children}
    </StyledSubtitle>
  )
}

export {Subtitle}