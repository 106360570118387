import axios from "axios"

const localStorageKey = '__auth_provider_token__'

const handleUserResponse = (data) =>  {
  window.localStorage.setItem(localStorageKey, data.accessToken)
  return data
}

export const getToken = () => {
  return window.localStorage.getItem(localStorageKey)
}

export const login = async (ep, {username, password}) => {
  return client(ep, {username, password}).then(handleUserResponse)
}

export const me = async (ep, token) => {
  return client(ep, null, token).then(res => res)
}

export const logout = async (ep, token) => {
  window.localStorage.removeItem(localStorageKey)
  // window.location.reload()
  // return client(ep, null, token).then(async res => {
  //   return res
  // })
}

const authURL = process.env.REACT_APP_API_URL
const client = async(endpoint, data, token) => {
  const config = {
    method: 'POST',
    data: data ? data : undefined,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token}` : undefined,
    },
    auth: {
      username: data?.username,
      password: data?.password
    }
  }

  const req = axios(`${authURL}/${endpoint}`, config).then(async response => {
    const data = response.data
    if (response.status === 200) {
      return data
     } else {
       return Promise.reject(data)
     }
  })

  return req
}
