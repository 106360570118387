import React, {useState} from 'react'
import {useQuery} from 'react-query'
import {Select, DownloadButton} from '../styles'
import {TwoItemContainer, RecommendationContainer, ScoreContainer, Score, MaxScore} from './styles'
import {RegularText, Title, Collapse} from 'UI/atoms'
import {CloudArrowDownload, CheckCircle, Spinner} from 'shared/assets/svgs'

import {ProgressCircleChart} from './UI/molecules/ProgressCircleChart'
import {Compromise, Implementation, Measurement, Total} from './UI/molecules/index'

import {client} from 'utils/api-client'

import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { Finished } from 'pages/HomeFlow/Finished'

const MODULES_TEXTS = {
  Compromiso: <Compromise />,
  Implementación: <Implementation />,
  Medición: <Measurement />
}

const DiagnosticsContainer = ({formulary}) => {
  const [formSelectedId, setFormSelectedId] = useState(formulary?.forms[0]?.id)
  const {data: diagnosisForm, status: diagnosisFormStatus} = useQuery(['diagnosis-form', formSelectedId], () => client(`org-admin/organization/forms/${formSelectedId}/diagnosis`))

  const handleFormSelected = ({target}) =>{
    setFormSelectedId(target.value);
  }
  
  function downloadPdf() {
    const input = document.getElementById('capture')
    
    const htmlWidth = document.getElementById("capture").offsetWidth
    const htmlHeight = document.getElementById("capture").offsetHeight
    const pdfWidth = htmlWidth + (15 * 2);
    const pdfHeight = (pdfWidth * 1.5) + (15 * 2);

    let doc = new jsPDF('p', 'pt',[pdfWidth, pdfHeight], true, 'FAST');
    
    const pageCount = Math.ceil(htmlHeight / pdfHeight) - 1;
  
    html2canvas(input).then(function(canvas) {
      canvas.getContext('2d');
  
      const image = canvas.toDataURL("image/png", 1.0);
      doc.addImage(image, 'PNG', 15, 15, htmlWidth, htmlHeight);
  
  
      for (var i = 1; i <= pageCount; i++) {
        doc.addPage(pdfWidth.toString(), pdfHeight.toString());
        doc.addImage(image, 'PNG', 15, -(pdfHeight * i)+15, htmlWidth, htmlHeight);
      }
  
      doc.save("Diagnóstico.pdf");
    });
  };

  const calculatePercentage = (score, maxScore) => {
    return score * 100 / maxScore
  }

  return (
    <>
      {diagnosisFormStatus === 'loading'
        ? <Spinner />
        : <div>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <label htmlFor='years' style={{fontSize:'14px'}}>Año:</label>
            {/* to do: remove native select arrow and add custom overlayed arrow for better positioning */}
            <Select name='years' id='years' 
              defaultValue={formulary?.forms[0]?.id} 
              onChange={handleFormSelected}
            >
              {formulary?.forms.map(form => (
                <option 
                  key={form.id} 
                  value={form.id}
                >
                  {form.publishedDate.slice(0, 10)}
                </option>
              ))}
            </Select>
            <DownloadButton onClick={() => downloadPdf()}><CloudArrowDownload/>Descargar informe</DownloadButton>
          </div>
        
          <div id='capture' style={{background: '#411968'}}>
          <TwoItemContainer>
              <ScoreContainer>
                <Title fontSize='lg' style={{fontFamily: 'Readex Pro', color: '#6C3F98'}}>
                  Total
                </Title>
                <ProgressCircleChart  percentage={calculatePercentage(diagnosisForm?.form?.score, diagnosisForm?.form?.maxScore)}>
                  Graph
                </ProgressCircleChart>
                <div style={{margin: '-4rem 0 2rem 0'}}>
                  <Title fontSize='lg' style={{display: 'flex', justifyContent: 'center', fontFamily: 'Readex Pro', color: '#6C3F98', textAlign: 'center'}}>
                    {diagnosisForm?.form?.score} pts.
                  </Title>
                  <RegularText color='#6C3F98' style={{textAlign: 'center'}}>
                    de {diagnosisForm?.form?.maxScore.toFixed(1)}pts.
                  </RegularText>
                </div>
              </ScoreContainer>
              <span>
              <Collapse >
                <Total />
              </Collapse>
              </span>
          </TwoItemContainer>
          
          {diagnosisForm?.form?.modules?.map(diagnosisModule => (
            <div key={diagnosisModule.name}>
              <TwoItemContainer >
                <ScoreContainer>
                  <Title fontSize='lg' style={{fontFamily: 'Readex Pro', color: '#6C3F98'}}>
                    {diagnosisModule?.name}
                  </Title>
                  <ProgressCircleChart percentage={calculatePercentage(diagnosisModule?.score, diagnosisModule?.maxScore)}>
                    Graph
                  </ProgressCircleChart>
                  <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', margin: '-4rem 0 2rem 0'}}>
                    <Title fontSize='lg' style={{fontFamily: 'Readex Pro', color: '#6C3F98', textAlign: 'center'}}>
                      {diagnosisModule?.score} pts.
                    </Title>
                    <RegularText color='#6C3F98'>
                      de {diagnosisModule?.maxScore.toFixed(1)}pts.
                    </RegularText>
                  </div>
                </ScoreContainer>
                <span>
                <Collapse>
                  {MODULES_TEXTS[diagnosisModule?.name]}
                </Collapse>
                </span>
              </TwoItemContainer>

              <Title justifyContent='center' color='white' style={{justifyContent: 'center'}}>
                Recomendaciones:
              </Title>
              <TwoItemContainer>
                <RecommendationContainer>
                  {diagnosisModule?.recommendations?.map(recommendation => (
                    <div key={recommendation.id} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1rem 0'}}>
                      <CheckCircle/>
                      <p>{recommendation.description}</p>
                    </div>
                  ))}
                </RecommendationContainer>
              </TwoItemContainer>
            </div>
          ))}
          </div>
        </div>
      }
    </>

  )
}

export default DiagnosticsContainer