
import styled from 'styled-components'

const StyledSelect = styled.select`
	display: block;

	width: ${props => props.width || '100%'};
	box-sizing: ${props => props.boxSizing ? props.boxSizing : 'border-box'};

	font-size: ${props => props.theme.fontSizes.sm};
	line-height: 18px;
	color: ${props => props.color ? props.color : props.theme.colors.gray};

	border: ${props => props.border || '1px solid rgba(46, 45, 90, 0.68)'};
	border-radius: 10px;

  	padding: ${props => props.padding || '0.6rem'}; 

  	background: ${props => props.bg};

	margin: ${props => props.margin || '1rem 0'};

	box-shadow: ${props => props.boxShadow};

`

export {StyledSelect}