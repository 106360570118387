import React from 'react'

const ArrowsClockWise = () => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.10803 10.1274H3.23303V5.25241" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19.3198 6.68023C18.4899 5.85031 17.5046 5.19198 16.4203 4.74283C15.336 4.29368 14.1738 4.0625 13.0001 4.0625C11.8264 4.0625 10.6642 4.29368 9.57983 4.74283C8.49549 5.19198 7.51022 5.85031 6.6803 6.68023L3.23315 10.1274" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.892 15.8726H22.767V20.7476" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.68018 19.3198C7.5101 20.1497 8.49536 20.808 9.57971 21.2572C10.6641 21.7063 11.8263 21.9375 12.9999 21.9375C14.1736 21.9375 15.3358 21.7063 16.4202 21.2572C17.5045 20.808 18.4898 20.1497 19.3197 19.3198L22.7669 15.8726" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    
    )
}

export {ArrowsClockWise}
