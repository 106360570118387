import React from 'react'
import {StyledTitle} from './styles'

const Title = ({children, margin, fontSize = 'extended', fontWeight = '700', textAlign, color, style}) => {
  return (
    <StyledTitle 
      fontSize={fontSize} 
      margin={margin}
      fontWeight={fontWeight}
      textAlign={textAlign}
      color={color}
      style={style}
    >
      {children}
    </StyledTitle>
  )
}

export {Title}