import React from 'react'

const Arrow = () => {
    return (
        <svg width="94" height="95" viewBox="0 0 94 95" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.70654 41.1699C29.3008 65.1338 60.4146 60.8857 83.6238 45.8318C84.023 45.5741 84.6191 45.7427 84.9526 46.2104C85.2862 46.6781 85.235 47.2685 84.8357 47.5262C60.9777 63.0032 28.9634 67.1491 1.62437 42.5135C1.19785 42.1304 1.09443 41.5177 1.39318 41.1449C1.69031 40.7736 2.27829 40.7848 2.70654 41.1699Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M83.7945 46.962C82.165 46.9473 80.1241 46.9525 79.8912 46.9461C73.579 46.7707 67.4412 45.5861 61.0881 43.4563C60.5724 43.2841 60.2112 42.732 60.2817 42.2263C60.3522 41.7206 60.8298 41.4505 61.3455 41.6227C67.5059 43.69 73.4584 44.8404 79.5813 45.0098C79.9382 45.0199 84.5254 45.0052 85.4668 45.0751C85.8526 45.1019 86.0861 45.2409 86.1391 45.2785C86.426 45.4687 86.5567 45.7008 86.626 45.8921C86.7027 46.1085 86.7489 46.4809 86.546 46.8679C86.3318 47.2717 85.6654 47.9516 85.5136 48.1401C83.2318 50.9928 80.3998 53.9202 78.4346 57.287C76.5603 60.4975 75.473 64.1118 76.5748 68.4905C76.7094 69.0235 76.4083 69.4858 75.9054 69.5227C75.4025 69.5595 74.8849 69.1546 74.7503 68.6216C73.5194 63.7261 74.6705 59.6608 76.7648 56.0729C78.7188 52.7264 81.5023 49.7993 83.7945 46.962Z" fill="white"/>
        </svg>


    )
}

export {Arrow}

