import React from 'react'
import {StyledText} from './styles'

const SmallText = ({children, margin = '0', weight, color, size = '12px', style}) => {
  return (
    <StyledText size={size} margin={margin} weight={weight} color={color} style={style}>
      {children}
    </StyledText>
  )
}


export {SmallText}