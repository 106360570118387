import { logout } from 'auth/provider'
import {useQuery, useMutation, useQueryClient, useErrorBoundary} from 'react-query'
import {client} from '../utils/api-client'

const useNewOrganizationRequest = () =>  {
  return useMutation(
    data => 
      client(`organizations`, {
        method: 'POST',
        data,
      })
  )
}

const useOrganizations = () => {
    const {data} = useQuery({
      queryKey: 'organizations',
      queryFn: () =>
        client(
            'organizations', 
            // {token: user.token}
        ).then(data => data),

    })
    return data ?? []
}

const useOrganizationById = (orgId) => {
    const {data} = useQuery({
      queryKey: `organization/${orgId}`,
      queryFn: () =>
        client(
            `organizations/${orgId}`, 
            // {token: user.token}
        ).then(data => data),

    })
    return data ?? {}
}

const useUpdateOrganizationById = (id) =>  {
  const queryClient = useQueryClient()
  return useMutation(updates => 
    client(`org-admin/organization`, {
      method: 'PUT',
      data: updates,
    }), {
    // Notice the second argument is the variables object that the `mutate` function receives
    onSuccess: (data, updates) => {
      if(updates.representative?.emailAddress) {
        logout()
      }
      queryClient.invalidateQueries('organization')
    },
  })
}

const useUpdatePassword = (setPasswordErrors) =>  {
  const queryClient = useQueryClient()
  return useMutation(updates => 
    client(`org-admin/organization/representative/password`, {
      method: 'PUT',
      data: updates,
    }), {
    // Notice the second argument is the variables object that the `mutate` function receives
    onSuccess: (data, updates) => {
      logout()
      queryClient.invalidateQueries('organization')
    },
    onError: () => {
      setPasswordErrors('invalid-password')
    }
  })
}

const useGetData = (ep) => {
  const {data} = useQuery({
    queryKey: `${ep}`,
    queryFn: () =>
      client(
          `${ep}`, 
          // {token: user.token}
      ).then(data => data),
  })
  return data ?? []
}

export {
  useOrganizations, 
  useOrganizationById, 
  useUpdateOrganizationById,
  useNewOrganizationRequest,
  useGetData,
  useUpdatePassword
}