import React, {useState} from 'react'
import styled from 'styled-components'
import {Button} from 'UI/atoms/index'
import {Dot, Caret} from 'shared/assets/svgs/index'

const Wizard = ({children}) => {
  const [activePageIndex, setActivePageIndex] = useState(0)
  const pages = React.Children.toArray(children)
  const currentPage = pages[activePageIndex]

  const goNextPage = () => {
    setActivePageIndex(prevState => prevState + 1)
  };

  const goPrevPage = () => {
    setActivePageIndex(prevState => prevState - 1)
  };

  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <div>
        <div>
          {currentPage}
        </div>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '2rem 0'}}>
          {activePageIndex > 0 
            ? <Button onClick={() => goPrevPage()}>
                <Caret position='left' color='white' />
              </Button>
            : null
          }
          <DotWrapper>
            <Dot isSelected={activePageIndex === 0} />
            <Dot isSelected={activePageIndex === 1} />
            <Dot isSelected={activePageIndex === 2}/>
            <Dot isSelected={activePageIndex === 3}/>
            <Dot isSelected={activePageIndex === 4}/>
          </DotWrapper>
          {activePageIndex < pages.length - 1
            ? <Button onClick={() =>  goNextPage()}>
                <Caret color='white' />
              </Button>
            : null
          }
        </div>
      </div>
    </div>
  )
}

const DotWrapper = styled.div`
  > * {
    margin: 0 0.5rem;
  }
`

export {Wizard}
