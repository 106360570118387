import React from 'react'

const ChartPieSlice = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 12V3" stroke="#3D4852" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.7943 7.5L4.20581 16.5" stroke="#3D4852" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.15008 13.6454C2.76041 11.5366 3.13532 9.35792 4.20749 7.50068C5.27966 5.64344 6.97883 4.22928 8.99988 3.51216V10.268L3.15008 13.6454Z" stroke="#3D4852" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 3C13.5768 3.00029 15.1258 3.41485 16.492 4.20216C17.8582 4.98947 18.9936 6.1219 19.7845 7.48605C20.5753 8.8502 20.9939 10.3982 20.9983 11.975C21.0026 13.5518 20.5927 15.1021 19.8094 16.4706C19.0261 17.8391 17.8971 18.9779 16.5353 19.7728C15.1735 20.5676 13.6267 20.9908 12.05 20.9999C10.4732 21.0089 8.92167 20.6035 7.55083 19.8243C6.18 19.0451 5.03793 17.9194 4.23901 16.56" stroke="#3D4852" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export {ChartPieSlice}
