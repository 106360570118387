import React, {useState, useEffect} from 'react'
import {ThemeProvider} from "styled-components";
import {theme} from 'utils/theme'
import GlobalStyles from 'utils/globalStyles';
import {UnauthenticatedApp} from 'unauthenticated-app'
import {AuthenticatedApp} from 'authenticated-app'
import {useAuth} from 'auth/context'
import {ErrorBoundary} from 'react-error-boundary'
import {ErrorFallback} from 'UI/molecules'
import {QueryClient, QueryClientProvider} from 'react-query';
import {Mobile} from 'UI/templates/Mobile';

const errors = [400, 401, 403, 404, 500]

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      useErrorBoundary: (error) => errors.includes(parseInt(error.response?.status))
    },
    
  },
})

const App = () => {
  const {user} = useAuth()
  const token = localStorage.getItem('__auth_provider_token__')

  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 912;

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        {isMobile
          ? <Mobile />
          : <>
              <GlobalStyles />
              {token 
                ? <ErrorBoundary FallbackComponent={ErrorFallback}><AuthenticatedApp /></ErrorBoundary> 
                : <UnauthenticatedApp />
              }
            </> 
        }   
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App;
