import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Link, useLocation} from 'react-router-dom'
import {Title, Input, Label} from 'UI/atoms/index'
import {Progress} from 'UI/molecules'
import {Wizard} from 'UI/organisms/Wizard'
import {MODULES_COLORS_TYPES, INPUT_TYPES} from './types'
import {usePostQuestionsByPage, useQuestionsChunks} from './hooks/index'
import {addBackgroundByModule, getCheckedValues, findQuestionNumber} from './utils/index'
import {Button} from 'UI/atoms/index'
import {ErrorBoundary} from 'react-error-boundary'
import {ErrorFallback} from 'UI/molecules'

const Cuestionary = ({moduleName, organizationId, formulary, questionsByModules, setIsModuleOpen}) => {
  const [radioAnswers, setRadioAnswers] = useState([])
  const [checkboxAnswers, setCheckboxAnswers] = useState([])

  const module = questionsByModules?.find(qbm => qbm.name === moduleName)

  const {chunks} = useQuestionsChunks(module)

  // get only the answers for each question and filter the questions that dont have answers yet
  const answers = module?.questions.map(question => question.answer).filter(ans => ans)
  // get amount of questions answered
  const answersAmount = answers?.length

  const questionNumber = findQuestionNumber(module)

  const {postAnswers, isError} = usePostQuestionsByPage([...radioAnswers, ...checkboxAnswers].filter(ans => ans.options.length !== 0))

  

  useEffect(() => {
    const backgrounds = addBackgroundByModule(module)
    return () => {
      for(var i = 0; i < backgrounds.length; i++){
        backgrounds[i].parentElement.style.backgroundColor = ''
      }
    }
  }, [module, addBackgroundByModule])


  const handleInputChange = (e, question, optionSelected, inputType) => {
    const defaultObj = {
      questionId: question.id,
      formularyId: formulary.id,
      organizationId,
      module: question.module,
      question_options: question.options,
      title: question.title,
      type: question.type,
      options: []
    }

    if(inputType === 'radio') {
      const isNewAnswer = radioAnswers.find(answer => answer.questionId === question.id) === undefined
      if(isNewAnswer) {
        setRadioAnswers((prevState) => [...prevState, {...defaultObj, options: [{id: optionSelected}]}])
      } else  { 
        const radioAnswersNotSelected = radioAnswers.filter(answer => answer.questionId !== question.id)
        setRadioAnswers(() => ([...radioAnswersNotSelected, {...defaultObj, options: [{id: optionSelected}]}]))
      }
    }

    if(inputType === 'checkbox') {
      const isNewAnswer = checkboxAnswers.find(answer => answer.questionId === question.id) === undefined
      const {checked} = e.currentTarget;
    
      if(!isNewAnswer) {
        const checkboxAnswersNotSelected = checkboxAnswers.filter(answer => answer.questionId !== question.id)
        const answerSelected = checkboxAnswers.find(answer => answer.questionId === question.id)

        let answerSelectedWithNewOptions = {
          ...answerSelected,
          options: checked 
            ? [...answerSelected.options, {id: optionSelected}] 
            : answerSelected.options.filter(ans => ans.id !== optionSelected)
        }

        setCheckboxAnswers(() => ([...checkboxAnswersNotSelected, answerSelectedWithNewOptions]))
      } else {
        const ansChecked = question?.answer?.options
        let questionOptionId = {id: optionSelected}
        let answerSelectedWithNewOptions = {
          ...defaultObj,
          options: checked 
            ? ansChecked ? [...ansChecked, questionOptionId] : [...defaultObj.options, questionOptionId] 
            : ansChecked 
                ? [...ansChecked].filter(ans => ans !== [] && ans.id !== optionSelected) 
                : [...defaultObj.options].filter(ans => ans !== [] && ans.id !== optionSelected)
        }

        setCheckboxAnswers((prevState) => ([...prevState, answerSelectedWithNewOptions]))
      }
    }
  }


  // get the last question with any answer and divide it by 3 to obtain the chunk it belongs to. Take in count that we have an UI with 3 questions per page, that represents each chunk.
  // at the end i'd to substract 1 to get the correct index.
  // of course if the user hasn't any questions answered yet i'd to show him the first chunk which is equal to 0. That's the purpose of the ternary.
  const lastQuestionAnswered = Math.ceil((module?.questions?.findLastIndex(q => q.answer)) / 3) - 1 >= 0 ? Math.ceil((module?.questions?.findLastIndex(q => q.answer)) / 3) : 0

  // error handling for react error boundary. as it can't handle asynchrinous data i need to handle the errors somehow.
  // for further information check here: https://reactjs.org/docs/error-boundaries.html#how-about-event-handlers
  if (isError) {
    throw new Error('Answer failed.');
  }

  return (
    <Wrapper>
      <HeaderWrapper>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Button 
            onClick={() => setIsModuleOpen(false)} 
            background='transparent'
            color='white'
            style={{margin: '0 0.25rem'}}
          >
            Cuestionario Insight D&I 2022
          </Button>
          <span>{'>'}</span>
          <Title 
            margin='0 1rem' 
            fontSize='customBase' 
            fontWeight='700' 
            color='white'
          >
            {module?.name}
          </Title>
        </div>
        <Questions>
          <div>
            <Answered>{answersAmount}</Answered>
            <Amount>/{module?.questions.length}</Amount>
          </div>
          <div style={{margin: '0 2rem'}} >
            <Progress answers={answersAmount} questions={module?.questions?.length} color={MODULES_COLORS_TYPES[module?.name]} />
          </div>
        </Questions>
      </HeaderWrapper>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Wizard moduleName={moduleName} postAnswers={postAnswers} setRadioAnswers={setRadioAnswers} setCheckboxAnswers={setCheckboxAnswers} currentModule={module?.name}  setIsModuleOpen={setIsModuleOpen} >
          {chunks?.map((chunk, idx) => (
            <div key={idx} style={{display: 'flex', justifyContent: 'flex-start'}}>
              {chunk?.map((question, idx) => (
                  <Question key={question.id}>
                    <div>
                      <Circle color={MODULES_COLORS_TYPES[module?.name]}>{questionNumber(question.id)}</Circle>
                      <Title
                        fontSize='customBase' 
                        fontWeight='700' 
                        color='white'
                        margin='1rem 0'
                      >
                        {question.title}
                      </Title>
                    </div>
                    <OptionsWrapper>
                      {question.options 
                        ? question.options.map((option) => ( 
                            <Option key={option.id}>
                              <Input 
                                defaultChecked={getCheckedValues(question, option)}
                                type={INPUT_TYPES[question.type]} 
                                id={`${question.type}-${option.id}`}
                                name={question.id}
                                value={option.id ? option.id : ''}
                                onChange={(e) => handleInputChange(e, question, option.id, INPUT_TYPES[question.type])} 
                                width='unset'
                              />
                              <Label 
                                htmlFor={`${question.type}-${option.id}`}
                                color='white'
                              >
                                {option.description}
                              </Label>
                            </Option>
                        ))
                        : <Textarea
                            placeholder='Escribir respuesta...'
                            background={MODULES_COLORS_TYPES[module?.name]}
                          />
                      }
                    </OptionsWrapper>
                  </Question>    
              ))}
            </div>  
          ))}
        </Wizard>
      </ErrorBoundary>
    </Wrapper>
  )
}

const OptionsWrapper = styled.div`
max-height: 50vh;
overflow-y: auto;

&::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #6C3F98; 
  border-radius: 8px;
}
`

const Wrapper = styled.section`
  background: ${props => props.theme.colors.violet};
`

const HeaderWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    margin: 0 0.5rem;
  }
`

const StyledLink = styled(Link)`
  font-weight: 700;
  font-size: ${props => props.theme.fontSizes.customBase};
  line-height: 25px;
  text-decoration-line: underline;

  margin: 0 1rem;

  color: white;
`

const Questions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Answered = styled.span`
  font-size: 90px;
`

const Amount = styled.span`
  font-size: ${props => props.theme.fontSizes.extended};
`

const Question = styled.div`
  max-width: 30%;
  margin: 2rem;
`

const Option = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin: 0.5rem;
  }
`

const Textarea = styled.textarea`
  height: 15rem;

  border: 1px solid ${props => props.theme.colors.white};
  border-radius: 10px;

  background: ${props => props.background};
  color: ${props => props.theme.colors.white};

  padding: 1rem;

  &::placeholder {
    color: white;
    font-family: 'Readex Pro';
  }
`

const Circle = styled.div`
  display: flex;
  justify-content: center;

  width: 50px;
  height: 50px;

  margin: 0.5rem;

  border-radius: 50%;

  background: ${props => props.theme.colors.white};
  color: ${props => props.color};

  font-size: ${props => props.theme.fontSizes.xlg};
  font-weight: 700;
`

export {Cuestionary}