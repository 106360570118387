import {useMutation, useQueryClient} from "react-query"
import {client} from "utils/api-client"

const errors = [400, 401, 403, 404, 500]

const useAddAnswerByQuestionId = () =>  {
  const queryClient = useQueryClient()
  // org-admin/organization/forms/:id/questions/:questionId/answer
  return useMutation(
    (data) => 
      client(`org-admin/organization/forms/${data.formularyId}/questions/${data.questionId}/answer`, {
        method: 'PUT',
        data: {
          options: data.options
        },
      }),
      {onSuccess: () => queryClient.invalidateQueries('organization-forms')}
  )
}

export {useAddAnswerByQuestionId}