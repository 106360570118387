
import React from 'react'
import styled from 'styled-components'
import {Button} from 'UI/atoms/index'
import {Title, Subtitle, RegularText} from 'UI/atoms/index'
import {useNavigate} from 'react-router-dom'

const Finished = () => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/resultados')
  }
  return (
    <Wrapper>
      <div style={{width: '50%', marginTop: '4rem'}}>
        <Title fontSize='xxl' fontWeight='700' color='white' textAlign='center' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          Tu formulario ha sido enviado con exito. <br />¡Gracias! 
        </Title>
        <Subtitle textAlign='center' fontSize='customBase' fontWeight='700' color='white'>
          Nos alegra que hayas realizado tu diagnóstico Insight de DEI para ser parte del cambio.
        </Subtitle>
        <Button 
          style={{margin: '2rem auto', padding: '1rem 2rem', borderRadius: '25px'}}
          background='white'
          color='#6C3F98'
          onClick={() => handleClick()}
          >
          Ver resultados
        </Button>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%:

  margin: 4rem;
  letter-spacing: 1px;

  > div {
    margin-top: 4rem;
    margin: 0 auto;
  }
`

export {Finished}