
import React, {useState} from 'react'
import styled from 'styled-components'
import {Title} from 'UI/atoms/index'
import {Button} from 'UI/atoms/index'
import {Modal} from 'UI/molecules/index'
import {Subtitle} from 'UI/atoms/index'
import {useQuestionsByModules} from './hooks/useQuestionsByModules'
import {MODULES_TYPES} from './types/modules'
import {Cuestionary} from 'pages/Cuestionary'
import {useCompleteFormAnswers} from 'hooks/formularies'

const Initialized = ({organization, formulary}) => {
  const [openSendFormModal, setOpenSendFormModal] = useState(false)
  const {questionsByModules} = useQuestionsByModules(formulary)
  const [isModuleOpen, setIsModuleOpen] = useState(false)
  const [moduleName, setModuleName] = useState('')

  const {mutate: completeFormAnswers} = useCompleteFormAnswers(formulary?.id)

  const handleModalActions = () => {
    completeFormAnswers('completed')
  }

  const startModule = (moduleName) => {
    setModuleName(moduleName)
    setIsModuleOpen(true)  
  }

  const handleTextToShow = (module) => {
    const questionsAnswered = module.questions.filter(question => question.answer).length
    const questionsAmount = module.questions.length

    switch (true) {
      case questionsAnswered === questionsAmount:
        return 'Revisar'
      case questionsAnswered === 0:
        return 'Comenzar'
      case questionsAnswered > 0:
        return 'Continuar'
    }
  }

  // check if every questions has been answered
  const showSendFormularyButton = formulary?.questions?.every(item => item.answer)

  // Para enviar formulario voy a tener que hacer lo mismo que en la vista de NotInitialized, hay que ejecutar una mutation y con un PUT actualizar el answerStatus a 'completed' ver formularies.js

  return (
    <Wrapper>
      {!isModuleOpen 
        ? <section style={{position: 'relative'}}>
            <Title fontSize='lg' fontWeight='700' color='white' textAlign='center' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              Cuestionario Insight D&I 2022
            </Title>
            <Modules>
              {questionsByModules?.map((module, idx) => (
                <ModulesWrapper isDisabled={organization?.status === 'finished'} key={module.name} background={MODULES_TYPES[module.name].color}>
                  <Circle>
                    {idx + 1}
                  </Circle>
                  <Title fontSize='lg' fontWeight='700' color='white' textAlign='center' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {MODULES_TYPES[module.name].name}
                  </Title>
                  <Questions>
                    <Answered>{module.questions.filter(question => question.answer).length}</Answered>
                    <Amount>/{module.questions.length}</Amount>
                  </Questions>
                  <Button 
                    type='submit' 
                    background='white'
                    fontSize='sm'
                    borderRadius='10px'
                    color={MODULES_TYPES[module.name].color}
                    style={{
                      width: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    
                      position: 'absolute',
                      left: '50%',
                      transform: 'translate(-50%, 0)',
                      bottom: '-6.5%',

                      padding: '1rem',
                      textTransform: 'uppercase'
                    }}
                    onClick={() => startModule(module.name)}
                  >
                  {handleTextToShow(module)}
                  </Button>
                </ModulesWrapper>
              ))}
            </Modules>
          {showSendFormularyButton 
            ? <Button 
                background='transparent' color='white' style={{position: "absolute", bottom: 0, right: "-5%"}}
                onClick={() => setOpenSendFormModal(true)}
              >
                <Baloon>
                  Enviar formulario!
                </Baloon>
              </Button>
               
            : null}
          </section>
        : <Cuestionary 
            moduleName={moduleName} 
            organizationId={organization?.id} 
            formulary={formulary} 
            questionsByModules={questionsByModules}  
            setIsModuleOpen={setIsModuleOpen}
          />
      }


      {openSendFormModal 
        ?  <Modal 
              isOpen={openSendFormModal}
              toggleModal={setOpenSendFormModal}
              width='35rem'
              height='30rem'
              justifyCont='flex-start'
              alignItems='flex-start'
            >
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: '4rem'}}>
                <Title textAlign='center' fontSize='lg' fontWeight='600' style={{fontFamily: 'Readex Pro'}}>
                  ¡Atención!
                </Title>
                <Subtitle fontSize='regular' color='#2E2D5A' weight='400' textAlign='center'>
                  Al enviar el cuestionario Insight DEI 2022 confirmo que he leído y acepto el tratamiento de confidencialidad de los datos registrados según lo establecido en la sección de Políticas de privacidad
                </Subtitle>
                <div style={{display: 'flex', margin: '2rem 0'}}>
                  <Button 
                    background='transparent' color='#2E2D5A' style={{fontFamily: 'Readex Pro', margin: '0 1rem', border: '1px solid #2E2D5A', borderRadius: '10px', padding: '0.75rem 1rem'}}
                    onClick={() => setOpenSendFormModal(false)}
                  >
                    Cancelar
                  </Button>
                  <Button 
                    background='#00B9B4' color='white' br='lg' style={{fontFamily: 'Readex Pro', margin: '0 1rem', borderRadius: '10px', padding: '0.75rem 1.5rem'}}
                    onClick={() => handleModalActions('send')}
                  >
                    Enviar
                  </Button>
                </div>
              </div>
            </Modal>
        : null
      }

    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin: 2rem 0;
  }
`

const Modules = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 4rem 0;
`

const ModulesWrapper = styled.div`
  position: relative;

  width: 25vw;
  height: 18vw;

  text-align: center;
  background: ${props => props.background};
  border-radius: 20px;

  margin: 0 1rem;
`

const Questions = styled.div``

const Answered = styled.span`
  font-size: 90px;
`

const Amount = styled.span`
  font-size: ${props => props.theme.fontSizes.extended};
`

const Percentage = styled.span`
  font-size: ${props => props.theme.fontSizes.xs};
`

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: -15%;

  width: 4rem;
  height: 4rem;

  margin: 0;

  border-radius: 50%;

  background: ${props => props.theme.colors.violet};
  color: white;

  font-size: ${props => props.theme.fontSizes.xlg};
  font-weight: 700;
`

const Baloon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 150px;
  height: 150px;

  border-radius: 50%;

  background: #E0FA27;
  color: ${props => props.theme.colors.violet};
`

export {Initialized}