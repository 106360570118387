const Total = () => {
    return (
      <>
       <p>El índice Insight de Diversidad, Equidad e Inclusión (DEI) mide el nivel de desarrollo de buenas prácticas en DEI en la organización. Refleja el puntaje total de las diferentes dimensiones de la gestión: compromiso, implementación y medición y reporte. El nivel de madurez en la gestión de la DEI se mide a partir de 5 niveles, siendo el quinto el más alto y el primero el más bajo:</p> 
        <p><b>Nivel 5. Sustentable (64 a 80 puntos):</b> la cultura inclusiva es un pilar de marca. Es parte del negocio y está integrada en sus actividades cotidianas, lo que se refleja en innovación, mejores prácticas y ejemplo para otras organizaciones.</p>
        <p><b>Nivel 4. Progresivo (48 a 64 puntos):</b> la DEI es un valor estratégico y cuenta con estructura y recursos. Se caracteriza por una gestión en DEI que tiende a integrar diferentes áreas de la organización.</p>
        <p><b>Nivel 3. Compromiso (32 a 48 puntos):</b> en la organización hay una creciente conciencia del valor de la DEI. El enfoque de gestión en DEI tiende a ser impulsado con un objetivo y líderes que asumen tal responsabilidad.</p>
        <p><b>Nivel 2. Conciencia (16 a 32 puntos):</b> la DEI se reconoce como un beneficio organizacional y la cultura inclusiva como clave para la innovación y la competitividad. Sin embargo, aún no se ha profundizado en su implementación.</p>
        <p><b>Nivel 1. Compliance (0 a 16 puntos):</b> cumple con los requisitos legales y ha notado la importancia de trabajar la DEI en su cultura. No obstante, la DEI no es parte de las metas o estrategia organizacional.</p>
      </>
    )
  }
  
  export {Total}