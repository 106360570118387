import React,{useState} from 'react'
import {Select, DownloadButton} from '../styles'
import {Container, SelectSection} from './styles'
import {CloudArrowDownload} from 'shared/assets/svgs'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

import { Button, Title } from 'UI/atoms'

import {LineChart, Line, XAxis, YAxis, Label, Tooltip, Legend, ResponsiveContainer, ScatterChart, Scatter, CartesianGrid, ZAxis} from 'recharts'
import { useLookups, useLookupsWithOrganizations } from 'hooks/lookups'
import {useLineChartBenchmark, useScatterChartBenchmark} from 'hooks/benchmark'

const SIZES_TYPES = {
  small: 'Pequeña',
  medium: 'Mediana',
  large: 'Grande'
}

const BenchmarkContainer = ({formularies}) => {
  const sectors = useLookupsWithOrganizations('sectors')
  const sizes = useLookupsWithOrganizations('sizes')
  const types = useLookupsWithOrganizations('types')
  const locations = useLookups('locations')
  const operationAreas = useLookupsWithOrganizations('operation-areas')

  const [lineChartYear, setLineChartYear] = useState('')
  const [lineChartArea, setLineChartArea] = useState('')
  const [lineChartCountry, setLineChartCountry] = useState('')
  const [lineChartOrganizationType, setLineChartType] = useState('')
  const [lineChartOrganizationSize, setLineChartSize] = useState('')

  const [scatterChartYear, setScatterFormSelectedId] = useState(formularies?.forms[0]?.id)
  const [scatterChartArea, setScatterChartArea] = useState('')
  const [scatterChartCountry, setScatterChartCountry] = useState('')
  const [scatterChartOrganizationType, setScatterChartType] = useState('')
  const [scatterChartOrganizationSize, setScatterChartSize] = useState('')

  const lineChartBenchmarkData = useLineChartBenchmark({organizationType: lineChartOrganizationType, organizationSize: lineChartOrganizationSize, location: lineChartCountry, operationArea: lineChartArea, year: lineChartYear})
  const scatterChartBenchmarkData = useScatterChartBenchmark(scatterChartYear, {organizationType: scatterChartOrganizationType, organizationSize: scatterChartOrganizationSize, location: scatterChartCountry, operationArea: scatterChartArea})

  const lineChartData = lineChartBenchmarkData?.forms?.map(form => {
    return {
      name: form.publishedDate.slice(0, 10),
      Promedio: form?.scoresMean ?  form?.scoresMean?.toFixed(2) : 50,
      Pts: form.score.toFixed(2)
    }
  })

  const scatterChartData = scatterChartBenchmarkData?.form?.consideredScores?.map(score => {
    return {
      y: parseInt(scatterChartBenchmarkData?.form?.publishedDate.slice(0, 10)),
      x: score
    }
  })

  const scatterOrgChartData = scatterChartBenchmarkData?.form?.consideredScores?.map(score => {
    return {
      y: parseInt(scatterChartBenchmarkData?.form?.publishedDate.slice(0, 10)),
      x: scatterChartBenchmarkData?.form?.score
    }
  })

  const downloadPdf = () => {
    let input = document.getElementById('capture')
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF('p', 'pt', 'a4', false);
        const width = pdf.internal.pageSize.getWidth()
        const height = pdf.internal.pageSize.getHeight()
        pdf.addImage(imgData, 'PNG', 0, 0, width, height)
        pdf.save("Benchmark.pdf")
      })
  }

  const optionSelected = (value, cb) => {
    cb(value)
  }

  const cleanLineChartFilters = () => {
    setLineChartYear('')
    setLineChartArea('')
    setLineChartCountry('')
    setLineChartType('')
    setLineChartSize('')
  }

  const cleanScatterChartFilters = () => {
    setScatterFormSelectedId(formularies?.forms[0]?.id)
    setScatterChartArea('')
    setScatterChartCountry('')
    setScatterChartType('')
    setScatterChartSize('')
  }

  return (
    <Container>
      <DownloadButton onClick={() => downloadPdf()} style={{maxWidth:'220px'}}><CloudArrowDownload/>Descargar informe</DownloadButton>
      <div id='capture' style={{background: '#411968', margin: '0 auto'}}>
        <SelectSection>
          {/* to do: remove native select arrow and add custom overlayed arrow for better positioning */}
          <Select name='area' id='area' value={lineChartArea} onChange={(e) => optionSelected(e.target.value, setLineChartArea)}>
              <option value='' disabled selected>Sector</option>
              {sectors?.organizationSectors?.map(sector => (
                <option 
                  key={sector.id} 
                  value={sector.name}
                >
                  {sector.name}
                </option>
              ))}
          </Select>
          <Select name='country' id='country' value={lineChartCountry} onChange={(e) => optionSelected(e.target.value, setLineChartCountry)}>
              <option value='' disabled selected>País</option>
              {locations?.locations?.map(country => (
                <option 
                  key={country.id} 
                  value={country.name}
                >
                  {country.name}
                </option>
              ))}
          </Select>
          <Select name='type' id='type' value={lineChartOrganizationType} onChange={(e) => optionSelected(e.target.value, setLineChartType)}>
              <option value='' disabled selected>Tipo de organización</option>
              {operationAreas?.organizationOperationAreas?.map(operationArea => (
                <option 
                  key={operationArea.id} 
                  value={operationArea.name}
                >
                  {operationArea.name}
                </option>
              ))}
          </Select>
          <Select 
            name='size' 
            id='size' 
            value={lineChartOrganizationSize} 
            onChange={(e) => optionSelected(e.target.value, setLineChartSize)}
          >
              <option value='' disabled selected>Tamaño</option>
              {sizes?.sizes?.map(type => (
                <option 
                  key={type.id} 
                  value={type.name}
                >
                  {SIZES_TYPES[type.name]}
                </option>
              ))}
          </Select>
          <Button 
            color='#6C3F98' 
            background='white' 
            borderRadius='10px'
            margin='0.5rem'
            fontSize='base'
            padding='0.75rem'
            onClick={() => cleanLineChartFilters()}
          >
            Limpiar filtros
          </Button>

        </SelectSection>

      <ResponsiveContainer width={800} height={400}>
        <LineChart
          data={lineChartData}
          margin={{ top: 30, right: 60, left: 60, bottom: 30 }}
          style={{
            background: 'white',
            borderRadius: '50px'
          }}
        >
          <Tooltip />
          <Legend verticalAlign='top' align='right' />
          <YAxis type="number" domain={[0, 120]} hide />
          <XAxis dataKey="name" interval="preserveStartEnd" tickLine={false} axisLine={false} />
          <Line type="monotone" name='Organizacion' dataKey="Pts" stroke="#E6148B" strokeWidth={4} activeDot={{ r: 12 }}  />
          <Line type="monotone" name='Promedio' dataKey="Promedio" stroke="#00B9B4" strokeWidth={4} />
        </LineChart>
      </ResponsiveContainer>
        <SelectSection>
          <Select name='year' id='year' value={scatterChartYear} onChange={(e) => optionSelected(e.target.value, setScatterFormSelectedId)}>
            <option value='Año' disabled selected>Año</option>
            {formularies?.forms?.map(form => (
              <option 
                key={form.id} 
                value={form.id}
              >
                {form.publishedDate.slice(0, 10)}
              </option>
            ))}
          </Select>
          <Select name='country' id='country' value={scatterChartCountry} onChange={(e) => optionSelected(e.target.value, setScatterChartCountry)}>
              <option value='' disabled selected>País</option>
              {locations?.locations?.map(country => (
                <option 
                  key={country.id} 
                  value={country.name}
                >
                  {country.name}
                </option>
              ))}
          </Select>
          <Select name='size' id='size' value={scatterChartOrganizationSize} onChange={(e) => optionSelected(e.target.value, setScatterChartSize)}>
              <option value='' disabled selected>Tamaño</option>
              {sizes?.sizes?.map(type => (
                <option 
                  key={type.id} 
                  value={type.name}
                >
                  {SIZES_TYPES[type.name]}
                </option>
              ))}
          </Select>
        </SelectSection>
        
        <SelectSection>
          <Select name='area' id='area' value={scatterChartArea} onChange={(e) => optionSelected(e.target.value, setScatterChartArea)}>
              <option value='' disabled selected>Sector</option>
              {sectors?.organizationSectors?.map(sector => (
                <option 
                  key={sector.id} 
                  value={sector.name}
                >
                  {sector.name}
                </option>
              ))}
          </Select>
          <Select name='type' id='type' value={scatterChartOrganizationType} onChange={(e) => optionSelected(e.target.value, setScatterChartType)}>
              <option value='' disabled selected>Tipo de organización</option>
              {types?.organizationTypes?.map(type => (
                <option 
                  key={type.id} 
                  value={type.name}
                >
                  {type.name}
                </option>
              ))}
          </Select>
          <Button 
            color='#6C3F98' 
            background='white' 
            borderRadius='10px'
            margin='0.5rem'
            fontSize='base'
            padding='0.75rem'
            onClick={() => cleanScatterChartFilters()}
          >
            Limpiar filtros
          </Button>
        </SelectSection>

      {scatterChartData?.length === 0
        ? <Title color='white'>Aún no tenemos suficientes registros para que puedas visualizar este gráfico. Intentá por favor en otro momento.</Title>
        : <ScatterChart
            width={750}
            height={400}
            style={{
              background: 'white',
              borderRadius: '50px'
            }}
          >
            <XAxis type="number" dataKey="x" name="Puntaje" unit="pts" padding={{ left: 100, right: 100 }}>
            </XAxis>
            <YAxis  type="number" dataKey='y' name='Año' hide={true}  />

            <ZAxis range={[500, 500]} />
            <Tooltip />
            <Scatter name="Otras organizaciones" data={scatterChartData} fill="#00B9B4"/>
            <Scatter name="Tu organización" data={scatterOrgChartData} fill="#E6148B"/>

            <Legend verticalAlign="top" align='right' height={48}/>
            <Line name='Tu organización' dataKey="Tu organización" stroke="#E6148B" />
            <Line name='Otras organizaciones' dataKey="Otras organizaciones" stroke="#00B9B4"  />
          </ScatterChart>
      }
        
      </div>
    </Container>
  )
}

export default BenchmarkContainer