

const newOrganizationReducer = (state, action) =>  {
    const {type, field, payload} = action
    switch (type) {
      case 'mutate': {
        return {
          ...state,
          [field]: payload,
        }
      }
      default: {
        throw new Error(`Unsupported action type: ${type}`)
      }
    }
  }
  
  export {newOrganizationReducer}