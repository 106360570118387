
import React from 'react'

const CloudCheck = () => {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.12496 13C8.12496 11.393 8.60148 9.82214 9.49427 8.48599C10.3871 7.14984 11.656 6.10844 13.1407 5.49348C14.6253 4.87852 16.259 4.71762 17.8351 5.03112C19.4112 5.34463 20.8589 6.11846 21.9952 7.25476C23.1315 8.39106 23.9053 9.8388 24.2188 11.4149C24.5323 12.991 24.3714 14.6247 23.7565 16.1093C23.1415 17.594 22.1001 18.8629 20.764 19.7557C19.4278 20.6485 17.8569 21.125 16.25 21.125H7.31246C6.50589 21.1242 5.70871 20.9519 4.97383 20.6194C4.23896 20.287 3.58319 19.8021 3.05007 19.1968C2.51694 18.5916 2.11864 17.8798 1.88162 17.1089C1.64459 16.3379 1.57426 15.5254 1.67528 14.7251C1.7763 13.9249 2.04638 13.1553 2.46757 12.4675C2.88876 11.7796 3.45144 11.1892 4.11826 10.7354C4.78509 10.2817 5.5408 9.97492 6.33524 9.83555C7.12968 9.69618 7.94467 9.72738 8.72613 9.92708" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.5 11.7812L14.625 16.6562L12.1875 14.2188" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export {CloudCheck}

