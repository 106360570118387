import styled from 'styled-components'

const StyledText = styled.p`
  font-size: ${props => props.size};
  font-weight: ${props => props.weight};
  color: ${props => props.color || 'rgba(46, 45, 90, 0.68)'}; 

  margin: ${props => props.margin};
`

export {StyledText}