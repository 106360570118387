import React, {useState, useReducer} from 'react'
import styled from 'styled-components'
import {Input, Title, Label, Select} from 'UI/atoms'
import {useUpdateOrganizationById, useUpdatePassword} from 'hooks/organizations'
import {Button} from 'UI/atoms/index'
import {useNavigate} from 'react-router-dom'
import {editOrgReducer} from './reducers/editOrgReducer'
import {Spinner} from 'shared/assets/svgs'
import {useLookups, useLookupsWithOrganizations} from 'hooks/lookups'

const Profile = ({organization}) => {
  const navigate = useNavigate()
  const [actualPassword, setActualPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmedPassword, setConfirmedPassword] = useState('')
  const [passwordErrors, setPasswordErrors] = useState('')

  const [isOpenEmailChangeModal, setIsOpenEmailChangeModal] = useState(false)

  const [showPassword, setShowPassword] = useState({
    'current-password': false,
    'new-password': false,
    'confirm-new-password': false
  })
  // const passwordtype = showPassword ? 'text' : 'password'

  const departments = useLookupsWithOrganizations('departments')
  const locations = useLookups('locations')
  const types = useLookupsWithOrganizations('types')
  const pronouns = useLookups('pronouns')

  const [state, dispatch] = useReducer(editOrgReducer, {})
  const change = (field, value) => dispatch({type: 'mutate', field, payload: value})

  const {mutate} = useUpdateOrganizationById(organization?.id)
  const {mutate: changePassword} = useUpdatePassword(setPasswordErrors)

  const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')

  const handleSubmit = (e) => {
    e.preventDefault()
    if(state.representative?.emailAddress) {
      setIsOpenEmailChangeModal(true)
    }
    mutate({...state})
    navigate('/cuestionario')
  }

  const handleChangePassword = () => {
    if(newPassword !== confirmedPassword) {
      setPasswordErrors('no-matching-password')
      return
    }
    if(strongPassword.test(confirmedPassword)) {
      changePassword({
        oldPassword: actualPassword,
        newPassword: confirmedPassword
      })
    } else {
      setPasswordErrors('password-strength-failed')
    }
  }


  if(!organization) return (
    <Spinner />
  )

  return (
    <>
    <Wrapper>
      <div style={{width: '100%'}}>
        <Title margin='0 0 4rem 0' color='white' style={{display: 'flex', justifyContent: 'center'}}>Mi cuenta</Title>
        <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
          <form 
            onSubmit={handleSubmit}
            style={{display: 'flex', justifyContent: 'space-evenly'}}
          >
            <div style={{margin: '0 2rem'}}>
              <Label htmlFor='organization-name'>
                Nombre organización
              </Label>
              <Input 
                key={organization.id}
                name='organization-name' 
                type='text'
                defaultValue={organization.name}
                onChange={(e) => change('name', e.target.value)}
                width='20vw' bg='#411968' color='white' border='1px solid rgba(255, 255, 255, 0.68)' hasBorder 
              />

              <Label htmlFor='types'>
                Tipo de organización
              </Label>
              <Select 
                name='types'
                value={state.type ? state.type : organization?.type}
                onChange={(e) => change('type', e.target.value)}
                width='20vw' color='white' bg='#411968' border='1px solid rgba(255, 255, 255, 0.68)' hasBorder 
              >
                {types?.organizationTypes?.map(type => (
                  <option 
                    key={type.name} 
                    value={type.name}
                  >
                    {type.name}
                  </option>
                ))}
              </Select>

              <Label htmlFor='location'>
                País
              </Label>
              <Select 
                name='location'
                value={state.location ? state.location : organization?.location}
                onChange={(e) => change('location', e.target.value)}
                width='20vw' color='white' bg='#411968' border='1px solid rgba(255, 255, 255, 0.68)' hasBorder 
              >
                {locations?.locations?.map(location => (
                  <option 
                    key={location.name} 
                    value={location.name}
                  >
                    {location.name}
                  </option>
                ))}
              </Select>

              <Label htmlFor='employeesNumber'>
                Cantidad de empleadxs
              </Label>
              <Input 
                name='employeesNumber' 
                type='number'
                defaultValue={organization?.employeesNumber}
                onChange={(e) => change('employeesNumber', e.target.value)}
                width='20vw' color='white' bg='#411968' border='1px solid rgba(255, 255, 255, 0.68)' hasBorder 
              />
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '1px solid #F5F5F5', height: '25vh'}}></div>
            <div style={{margin: '0 2rem'}}>
              <Label htmlFor='representative_name'>
                Responsable plataforma
              </Label>
              <Input 
                name='representative_name' 
                type='text'
                defaultValue={organization.representative?.name}
                onChange={(e) => change('representative', {...state?.representative, name: e.target.value})}
                width='20vw' color='white' bg='#411968' border='1px solid rgba(255, 255, 255, 0.68)' hasBorder 
              />

              <Label htmlFor='pronoun'>
                Pronombre
              </Label>
              <Select 
                name='pronoun'
                defaultValue={organization.representative?.pronoun}
                onChange={(e) => change('representative', {...state?.representative, pronoun: e.target.value})}
                width='20vw' color='white' bg='#411968' border='1px solid rgba(255, 255, 255, 0.68)' hasBorder 
              >
                {pronouns?.pronouns?.map(pronoun => (
                  <option 
                    defaultValue={organization.representative?.pronoun} 
                    key={pronoun.id} 
                    value={pronoun.name}
                  >
                    {pronoun.name}
                  </option>
                ))}
              </Select>

              <Label htmlFor='email'>
                Contacto
              </Label>
              <Input 
                name='email' 
                type='text'
                defaultValue={organization.representative?.emailAddress}
                onChange={(e) => change('representative', {...state?.representative, emailAddress: e.target.value})}
                width='20vw' color='white' bg='#411968' border='1px solid rgba(255, 255, 255, 0.68)' hasBorder 
              />

              <Label htmlFor='contactNumber'>
                Teléfono
              </Label>
              <Input
                name='contactNumber' 
                type='number'
                defaultValue={organization.representative?.contactNumber}
                onChange={(e) => change('representative', {...state?.representative, contactNumber: e.target.value})}
                width='20vw' color='white' bg='#411968' border='1px solid rgba(255, 255, 255, 0.68)' hasBorder 
              />

              <Label htmlFor='organizationDepartment'>
                Área
              </Label>
              <Select 
                name='organizationDepartment'
                defaultValue={organization.representative?.department}
                onChange={(e) => change('representative', {...state?.representative, department: e.target.value})}
                width='20vw' color='white' bg='#411968' border='1px solid rgba(255, 255, 255, 0.68)' hasBorder 
              >
                {departments?.organizationDepartments?.map(department => (
                  <option 
                    defaultValue={organization.representative?.department} 
                    key={department.id} 
                    value={department.name}
                  >
                    {department.name}
                  </option>
                ))}
              </Select>
              <div style={{display: 'flex', margin: '2rem 0 0 0'}}>
                <Button 
                  onClick={() => navigate('/inicio')}
                  fontSize='sm' 
                  style={{background: 'white', color: 'black', fontFamily: 'Readex Pro', padding: '0.75rem 1.5rem', margin: '0 1rem'}}
                  borderRadius='12px'
                >
                  Cancelar
                </Button>
                <Button 
                  type='submit'
                  fontSize='sm' 
                  style={{background: '#00B9B4', color: 'white', fontFamily: 'Readex Pro', padding: '0.75rem 1.5rem', margin: '0 1rem'}}
                  borderRadius='12px'
                >
                  Guardar
                </Button>
              </div>
            </div>
          </form>
          <div style={{borderRight: '1px solid #F5F5F5', height: '25vh'}}></div>
            <div style={{margin: '0 2rem'}}>
              <Label>
                Contraseña actual
              </Label>
              <InputWrapper>
                <Input
                  type={showPassword['current-password'] ? 'text' : 'password'}
                  name='password'
                  width='20vw' color='white' bg='#411968' border='1px solid rgba(255, 255, 255, 0.68)' 
                  hasBorder
                  value={actualPassword}
                  onChange={(e) => setActualPassword(e.target.value)}
                />
              </InputWrapper>

              <Label>
                Nueva contraseña
              </Label>
              <InputWrapper>
                <Input
                  type={showPassword['new-password'] ? 'text' : 'password'}
                  name='password'
                  width='20vw' color='white' bg='#411968' border='1px solid rgba(255, 255, 255, 0.68)' 
                  hasBorder
                  value={newPassword}
                  pattern='?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,}'
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </InputWrapper>

              <Label>
                Confirmar contraseña
              </Label>
              <InputWrapper>
                <Input
                  type={showPassword['confirm-new-password'] ? 'text' : 'password'}
                  name='password'
                  width='20vw' color='white' bg='#411968' border='1px solid rgba(255, 255, 255, 0.68)' 
                  hasBorder
                  value={confirmedPassword}
                  pattern='?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,}'
                  onChange={(e) => setConfirmedPassword(e.target.value)}
                />
              </InputWrapper>
              {passwordErrors === 'no-matching-password' ? <ErrorMessage>La nueva contraseña es distinta a la contraseña confirmada</ErrorMessage>: null}
              {passwordErrors === 'invalid-password' ? <ErrorMessage>La contraseña actual es incorrecta.</ErrorMessage>: null}
              {passwordErrors === 'password-strength-failed' ? <ErrorMessage>La contraseña debe tener un mínimo de 8 caracteres y debe incluir como mínimo una mayúscula, un símbolo y un número.</ErrorMessage>: null}
              <div style={{display: 'flex', margin: '2rem 0 0 0'}}>
                <Button 
                  onClick={() => navigate('/inicio')}
                  fontSize='sm' 
                  style={{background: 'white', color: 'black', fontFamily: 'Readex Pro', padding: '0.75rem 1.5rem', margin: '0 1rem'}}
                  borderRadius='12px'
                >
                  Cancelar
                </Button>
                <Button 
                  type='submit'
                  fontSize='sm' 
                  style={{background: '#00B9B4', color: 'white', fontFamily: 'Readex Pro', padding: '0.75rem 1.5rem', margin: '0 1rem'}}
                  borderRadius='12px'
                  onClick={handleChangePassword}
                >
                  Cambiar contraseña
                </Button>
              </div>
            </div>
        </div>
      </div>
    </Wrapper>
        </>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
`

const InputWrapper = styled.div`
  position: relative;
`

const ErrorMessage = styled.p`
  font-size: ${props => props.theme.fontSizes.xs};
  color: ${props => props.theme.colors.white};
  margin-top: 0.5rem;
`

export {Profile}