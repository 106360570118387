import styled from 'styled-components'

export const Title = styled.h2`
font-family: 'Readex Pro';
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 21px;
text-align: center;`

export const TwoItemContainer = styled.div`
display: flex;
justify-content: center;
align-items: start;
margin: 2rem 0;
& img{
    min-width: 300px;
    width: 100%;
    height: auto;
}
& span {
    max-width: 50%;
    & p{
        padding: .5rem 2rem;
    }
}
}
`
export const RecommendationContainer = styled.div`
margin: 0 2rem;

& svg{
    min-width: 50px;
}
& p{
    margin: 0;
}
`

export const ScoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 25vw;

  background: ${props => props.theme.colors.white};
  border-radius: 50px;

`

export const Score = styled.div`
`

export const MaxScore = styled.div`
`