import * as React from 'react'
import {QueryClient, QueryClientProvider } from 'react-query'
import {AuthProvider} from './context'
import {ModalProvider} from 'styled-react-modal'

const queryClient = new QueryClient()

const queryConfig = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    retry(failureCount, error) {
      if (error.status === 404) return false
      else if (failureCount < 2) return true
      else return false
    },
  },
}

const AppProviders = ({children}) => {
  return (
    <QueryClientProvider client={queryClient} config={queryConfig}>
      <ModalProvider>
        <AuthProvider>{children}</AuthProvider>
      </ModalProvider>
    </QueryClientProvider>
  )
}

export {AppProviders}
