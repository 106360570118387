import React from 'react'
import {StyledText} from './styles'

const RegularText = ({children, margin = '0', color, style}) => {
  return (
    <StyledText margin={margin} color={color} style={style}>
      {children}
    </StyledText>
  )
}



export {RegularText}