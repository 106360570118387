import {useQuery, useMutation, useQueryClient} from 'react-query'
import {client} from '../utils/api-client'

const useFormularies = () => {
  const {data} = useQuery({
    queryKey: 'formularies',
    queryFn: () =>
      client(
          'formularies', 
          // {token: user.token}
      ).then(data => data)
  })
  return data ?? []
}

const useFormularyById = (formId) => {
    const {data, isLoading} = useQuery({
      queryKey: `formularies/${formId}`,
      queryFn: () =>
        client(
            `formularies/${formId}`, 
            // {token: user.token}
        ).then(data => data),
    })
    return {data, isLoading} ?? {}
}


const useMutateFormStatusByFormId = (formId) =>  {
  return useMutation(
    data => 
      client(`formularies/${formId}`, {
        method: 'PUT',
        data,
      })
  )
}

const useRemoveQuestion = (formId) =>  {
  return useMutation(
    data => 
      client(`formularies/${formId}`, {
        method: 'PUT',
        data,
      })
  )
}

const useEditQuestion = (formId) =>  {
  return useMutation(
    data => 
      client(`formularies/${formId}`, {
        method: 'PUT',
        data,
      })
  )
}

const useCreateFormAnswers = (formId) =>  {
  const queryClient = useQueryClient()
  return useMutation(
    (data) => 
      client(`org-admin/organization/forms/${formId}`, {
        method: 'PUT',
        data: {answerStatus: data}
      }),
      {
        onSuccess: () => queryClient.invalidateQueries('organization-forms')
      }
  )
}

const useCompleteFormAnswers = (formId) =>  {
  const queryClient = useQueryClient()
  return useMutation(
    (data) => 
      client(`org-admin/organization/forms/${formId}`, {
        method: 'PUT',
        data: {answerStatus: data}
      }),
      {
        onSuccess: () => queryClient.invalidateQueries('organization-forms')
      }
  )
}

const useModules = () => {
    const {data} = useQuery({
      queryKey: 'modules',
      queryFn: () =>
        client(
            'modules', 
            // {token: user.token}
        ).then(data => data),
    }
    )
    return data ?? []
}

export {
    useFormularies, 
    useFormularyById,
    useModules,
    useRemoveQuestion,
    useEditQuestion,
    useMutateFormStatusByFormId,
    useCreateFormAnswers,
    useCompleteFormAnswers
}