import React from 'react';
import {Wrapper, EmptyBar, ProgressBar} from './styles'

const Progress = ({ ...props }) => {
  const { answers, questions } = props;

  const percentageCompleted = () => {
    return (answers * 100) / questions;
  }

  return (
    <Wrapper>
      <EmptyBar></EmptyBar>
      <ProgressBar width={Math.ceil(percentageCompleted()) + '%'}>
        <span style={{display: 'inline-block', margin: '1rem 0'}}>{Math.ceil(percentageCompleted()) + '%'}</span>
      </ProgressBar>
    </Wrapper>
  )
}

export {Progress}