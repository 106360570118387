import React, {useState, useEffect} from 'react'

const useQuestionsChunks = (module = {}) => {
  const [chunks, setChunks] = useState([])

  useEffect(() => {
    const questionsCopy = [...module?.questions]
    const [list, chunkSize] = [questionsCopy, 3]
    const chunk = [...Array(Math.ceil(list.length / chunkSize))].map(_ => list.splice(0, chunkSize))
    setChunks(chunk)
  }, [module?.questions])
  
  return {
    chunks,
    setChunks
  }
}

export {useQuestionsChunks}