import React from 'react'
import {StyledInput} from './styles'

const Input = ({ 
  name,
  value,
  onChange,
  defaultValue,
  defaultChecked,
  autoComplete,
  type,
  id,
  placeholder,
  bg = '#FFFFFF', 
  hasBorder = false,
  border,
  borderRadius,
  padding,
  width,
  required,
  boxSizing,
  color,
  accentColor,
  pattern
}) => {
  return (
    <>
      <StyledInput 
        onChange={onChange}
        defaultValue={defaultValue}
        name={name}
        value={value}
        type={type}
        bg={bg}
        hasBorder={hasBorder}
        placeholder={placeholder}
        padding={padding}
        width={width}
        id={id}
        defaultChecked={defaultChecked}
        autoComplete={autoComplete}
        required={required}
        border={border}
        borderRadius={borderRadius}
        boxSizing={boxSizing}
        color={color}
        accentColor={accentColor}
        pattern={pattern}
      />
    </>
  )
}

export {Input}

