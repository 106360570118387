import React, {useState, useEffect} from 'react'

// scenes
import {Initialized} from './Initialized'
import {NotInitialized} from './NotInitialized'
import {Finished} from './Finished'
import {ErrorBoundary} from 'react-error-boundary'
import {ErrorFallback} from 'UI/molecules'

import {Spinner} from 'shared/assets/svgs'

const HomeFlow = ({organization, formulary}) => {
  const {answerStatus} = formulary
  return (
    <>
      {answerStatus === 'not-started' && <NotInitialized organization={organization} formularyId={formulary?.id}/>}
      {answerStatus === 'in-progress' && <ErrorBoundary FallbackComponent={ErrorFallback}><Initialized organization={organization} formulary={formulary} /></ErrorBoundary>}
      {answerStatus === 'completed' && <Finished />}
    </>
  )
}

export {HomeFlow}