import React from 'react'

const WinguIcon = () => {
    return (
        <svg width="90" height="21" viewBox="0 0 90 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1569_1489)">
        <path d="M54.3878 5.62817H51.771V16.0651H54.3878V5.62817Z" fill="white"/>
        <path d="M62.3341 5.42771C61.7122 5.41564 61.0957 5.54509 60.5322 5.8061C60.0603 6.01905 59.6465 6.34033 59.3251 6.74329C59.3023 6.70069 59.0467 6.21456 58.7 5.63069H56.7893V16.0676H59.3959V9.89317C59.3811 9.61003 59.4299 9.32716 59.5388 9.06498C59.6478 8.80279 59.8142 8.56785 60.0261 8.37712C60.4505 8.00992 60.9988 7.81405 61.5622 7.82833C61.8286 7.81783 62.0944 7.86032 62.3439 7.95329C62.5934 8.04626 62.8216 8.18782 63.0149 8.3696C63.2095 8.57108 63.3601 8.81015 63.4572 9.07187C63.5543 9.33359 63.5959 9.61233 63.5792 9.89066V16.0676H66.196V9.22661C66.196 8.07725 65.8383 7.15843 65.123 6.47015C64.7527 6.12152 64.3155 5.85005 63.8374 5.67184C63.3592 5.49364 62.8497 5.41232 62.3392 5.43273" fill="white"/>
        <path d="M87.3933 5.65073V11.8678C87.4074 12.149 87.3582 12.4297 87.2492 12.6898C87.1402 12.9498 86.9742 13.1826 86.7632 13.3713C86.3313 13.7388 85.776 13.9329 85.2068 13.9151C84.9411 13.925 84.676 13.8825 84.4271 13.79C84.1781 13.6975 83.9502 13.5569 83.7567 13.3763C83.5612 13.1759 83.4101 12.9374 83.3129 12.6759C83.2157 12.4145 83.1747 12.1358 83.1923 11.8578V5.65073H80.5857V12.5293C80.5857 13.6787 80.9417 14.5975 81.6537 15.2858C82.0242 15.6338 82.4615 15.9045 82.9397 16.0818C83.4179 16.2592 83.9273 16.3396 84.4375 16.3182C85.0676 16.3311 85.6925 16.2017 86.2646 15.9398C86.7502 15.7191 87.1734 15.3835 87.4971 14.9625C87.4971 14.9625 87.7628 15.4913 88.1045 16.0651H90.0076V5.65073H87.3933Z" fill="white"/>
        <path d="M53.0744 0.972279C52.8753 0.96496 52.6768 0.998341 52.4913 1.07034C52.3058 1.14234 52.1373 1.25142 51.9963 1.39076C51.8571 1.53228 51.7483 1.7003 51.6765 1.88464C51.6047 2.06899 51.5715 2.26583 51.5787 2.46327C51.5694 2.66022 51.6016 2.85694 51.6735 3.04082C51.7453 3.22471 51.8551 3.39171 51.9959 3.53113C52.1367 3.67055 52.3054 3.77931 52.4911 3.85045C52.6768 3.92158 52.8755 3.95353 53.0744 3.94424C53.2736 3.95161 53.4722 3.919 53.6584 3.84837C53.8445 3.77775 54.0143 3.67056 54.1575 3.53328C54.2983 3.39363 54.4084 3.22677 54.4811 3.04311C54.5538 2.85944 54.5876 2.66291 54.5802 2.46578C54.5884 2.26666 54.5551 2.06801 54.4824 1.88219C54.4097 1.69637 54.2991 1.52738 54.1575 1.38575C54.0145 1.24801 53.8448 1.14037 53.6587 1.06931C53.4725 0.998246 53.2738 0.965241 53.0744 0.972279Z" fill="white"/>
        <path d="M73.2188 13.9827C71.3638 13.9827 70.5717 12.4517 70.5717 10.8629C70.5717 9.27422 71.3663 7.74063 73.2188 7.74063C75.0713 7.74063 75.8634 9.27171 75.8634 10.8629C75.8634 12.4542 75.0738 13.9827 73.2188 13.9827ZM75.9216 6.79341C75.2459 5.92638 74.1071 5.42521 72.6949 5.42521C72.0482 5.40882 71.406 5.53708 70.8163 5.80043C70.2265 6.06378 69.7043 6.45544 69.2886 6.94627C68.4028 7.96198 67.96 9.26252 67.96 10.8479C67.96 12.4333 68.4028 13.7363 69.2886 14.7571C69.7029 15.2521 70.2257 15.6473 70.8172 15.9126C71.4087 16.1779 72.0534 16.3063 72.7025 16.2881C74.5601 16.2881 75.58 15.4186 75.8786 14.9901V15.6867C75.8899 16.1674 75.7193 16.6348 75.4003 16.9973C75.0808 17.364 74.6672 17.6384 74.2033 17.7917C73.6969 17.9603 73.1659 18.045 72.6317 18.0422C71.9982 18.0401 71.3668 17.9695 70.7488 17.8318L70.5944 17.7967L69.5948 19.7964C69.9335 19.9142 70.2791 20.0113 70.6299 20.087C71.3439 20.2413 72.0729 20.3177 72.8038 20.3151C73.5388 20.3202 74.2708 20.2215 74.9777 20.0219C75.6211 19.8417 76.23 19.5572 76.7795 19.1799C77.3086 18.8104 77.7372 18.3172 78.0272 17.744C78.3398 17.1218 78.4969 16.4343 78.4852 15.7394V5.62818H76.5796C76.1747 6.33484 75.9216 6.79341 75.9216 6.79341Z" fill="white"/>
        <path d="M45.601 13.4415L43.2449 5.66075H41.4835L39.1173 13.4415L36.9738 5.66075H34.2861L37.3837 16.0626H40.6205L42.3642 10.2164L44.1079 16.0626H47.3447L50.4195 5.66075H47.7319L45.601 13.4415Z" fill="white"/>
        <path d="M17.0876 8.30445C13.9191 6.51526 10.4621 5.51291 7.36453 5.51291C5.05419 5.48085 2.78535 6.12223 0.840332 7.35723C1.1796 8.5244 1.59034 9.67003 2.07026 10.7878C3.37611 9.63506 5.21848 8.99607 7.36453 8.99607C9.85982 8.99607 12.6942 9.823 15.3439 11.324C17.7944 12.7183 20.0456 14.4308 22.0376 16.4159C22.2047 16.5863 22.359 16.7542 22.5109 16.9171C23.2399 15.9671 23.9068 14.9719 24.5076 13.9376C22.3002 11.7393 19.8045 9.84465 17.0876 8.30445Z" fill="white"/>
        <path d="M7.36449 13.8349C6.2282 13.8349 5.39812 14.1381 5.08684 14.6693C4.95745 14.915 4.90628 15.1937 4.94006 15.4687C4.98052 15.8575 5.12851 16.2278 5.36775 16.5387C6.21066 17.6728 7.1414 18.7401 8.15154 19.7312C8.15154 19.7312 9.83447 17.7265 12.9599 15.589C12.9646 15.5861 12.9685 15.582 12.9712 15.5772C12.9739 15.5724 12.9753 15.5669 12.9753 15.5614C12.9753 15.5559 12.9739 15.5505 12.9712 15.5457C12.9685 15.5409 12.9646 15.5368 12.9599 15.5339C11.0568 14.4513 9.01958 13.8299 7.37714 13.8299" fill="white"/>
        <path d="M14.7716 12.3163C12.2915 10.9131 9.66212 10.1387 7.36423 10.1387C5.35737 10.1387 3.67191 10.7677 2.57104 11.8929C2.93658 12.678 3.34548 13.4426 3.79591 14.1832C3.83894 14.073 5.06127 12.6922 7.3617 12.6922C9.1332 12.6922 11.3349 13.3137 13.4886 14.5315C13.6758 14.6368 15.0374 15.4712 15.4701 15.7845C17.1477 16.9092 18.6779 18.2357 20.0254 19.7337C20.6581 19.1123 21.2402 18.4808 21.7767 17.8318C21.5995 17.6229 21.4097 17.415 21.2073 17.2078C19.2918 15.3006 17.1274 13.6556 14.7716 12.3163Z" fill="white"/>
        <path d="M3.23173 1.89443C2.13057 2.2911 1.05177 2.74615 0 3.25763C0.10379 4.24969 0.280567 5.23293 0.528921 6.19951C2.5931 4.97943 4.95806 4.3489 7.36187 4.37775C10.6518 4.37775 14.3138 5.42019 17.6568 7.31462C20.3643 8.85256 22.8606 10.729 25.0845 12.8977C25.7389 11.6549 26.3039 10.3679 26.775 9.04618C24.5886 7.08051 22.1951 5.35324 19.6359 3.89412C18.1284 3.03604 16.5432 2.31958 14.9009 1.75411C11.11 0.474409 6.99009 0.523953 3.23173 1.89443Z" fill="white"/>
        <path d="M14.081 3.93376e-06C13.7646 3.93376e-06 13.4508 3.93338e-06 13.1395 0.0150391C13.1348 0.0150391 13.1303 0.0168872 13.127 0.0201768C13.1237 0.0234664 13.1218 0.027928 13.1218 0.0325802C13.1218 0.0325802 13.1218 0.0476154 13.1395 0.0501213C15.6137 0.683681 17.991 1.64278 20.2078 2.9018C22.6915 4.31905 25.024 5.98138 27.1699 7.86341C27.6554 6.3649 27.9877 4.8219 28.1619 3.25763C23.7856 1.10874 18.9658 -0.00632861 14.081 3.93376e-06Z" fill="white"/>
        </g>
        <defs>
        <clipPath id="clip0_1569_1489">
        <rect width="90" height="20.3226" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    )
}

export {WinguIcon}
