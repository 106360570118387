import React from 'react'
import styled from 'styled-components'
import {Wrapper} from './styles'
import {Link, useLocation} from 'react-router-dom'
import {Nodos} from 'shared/assets/svgs/LoginIcons'
import {Burger, Chat, ChartPieSlice, ChartBar, ThumbsUp} from 'shared/assets/svgs/index'
import {Dropdown} from '../Dropdown'
import {DROPDOWN_TYPES} from 'types/dropdown-types'


const Sidebar = ({ organization, logout, setToggleDropdown, toggleDropdown}) => {
  const {pathname} = useLocation()

  const options = [
    { text: 'Cuestionario en curso', icon: <Chat />, route: '/cuestionario'},
    { text: 'Mis resultados', icon: <ChartPieSlice />, route: '/resultados'},
    { text: 'Indice', icon: <ChartBar />, route: '/indice'},
    // { text: 'Recomendaciones', icon: <ThumbsUp />, route: '/recomendaciones'},
  ]
  return (
    <Wrapper>
      <div style={{marginBottom: '3rem'}}>
        <Nodos />
      </div>
      <ListItem
        leftIcon={<Burger />}
        leftIconSpacing='24'
        my='24'
        color='white'
        onClick={() => setToggleDropdown('menu')}
      >
        MENÚ
        {toggleDropdown ===  DROPDOWN_TYPES.menu
          ? <Dropdown 
              options={options}
              position='right'
            />
          : null
        }
      </ListItem>
    </Wrapper>
  )
}

const ListItem = styled.a`
  font-size: ${props => props.theme.fontSizes.customBase};
  background: transparent;
  display: unset;

  text-decoration: none;

  cursor: pointer;
  color: ${props => props.theme.colors.white};

  margin: 0 2rem;
`



export {Sidebar}