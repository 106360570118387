const Compromise = () => {
    return (
      <>
        <p>El <b>compromiso</b> organizacional con la Diversidad, la Equidad y la Inclusión (DEI) se refiere a la posición y responsabilidad que se asume frente a la temática. Generalmente, se manifiesta en el desarrollo de políticas, acciones, programas y recursos con miras a lograr que los integrantes de la organización sean agentes de cambio.</p> 

        <p><b>Nivel 5. Sustentable (13 a 16 puntos):</b> la DEI es parte de los valores y misión organizacionales e impulsa su estrategia. Por ello, la gestión de la DEI es integral y transversal a la organización.</p>
                  
        <p><b>Nivel 4. Progresivo (10 a 13 puntos):</b> cuenta con un sistema de gestión de la DEI que involucra, no sólo una perspectiva de RRHH, RSE y/o Compliance, sino que se empieza a vincular con la estrategia de la organización en todas sus áreas.</p>

        <p><b>Nivel 3. Compromiso (6 a 10 puntos):</b> la organización reconoce beneficios de la diversidad y puede demostrar que está implementando algunas estrategias de DEI diseñadas para crear un entorno de trabajo inclusivo.</p>

        <p><b>Nivel 2. Conciencia (3 a 6 puntos):</b> la organización ha tomado conciencia de la importancia de trabajar en DEI; identifica y busca abordar alguna/s brecha/s en sus prácticas.</p>

        <p><b>Nivel 1. Compliance (0 a 3 puntos):</b> cumple con los requisitos legales y ha realizado acciones puntuales. Es importante asegurar el compromiso de la alta dirección con la DEI como un elemento estratégico.</p>
      </>
    )
  }
  
  export {Compromise}