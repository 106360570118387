import React from 'react'
import styled from 'styled-components'
import {RegularText} from 'UI/atoms'

const Mobile = () => {
 return (
  <Wrapper>
    <RegularText style={{textAlign: 'center', lineHeight: '30px', margin: '0 1rem', fontFamily: 'Readex Pro', fontSize: '20px'}}>
      ¡Hola!
      <br /><br />
      Te recordamos que, para una mejor experiencia, la aplicación debe ser utilizada desde un dispositivo del tipo desktop. 
      Es decir netbooks, notebooks, o computadoras de escritorio.
      <br /><br />
      ¡Disculpa las molestias ocasionadas!
    </RegularText>
  </Wrapper>
 )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100%;

  background: ${props => props.theme.colors.violet};
  color: ${props => props.theme.colors.white};

`

export {Mobile}