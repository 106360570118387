import React, {useState} from 'react'

import {useQuery} from 'react-query'
import {client} from 'utils/api-client'


import {Heading, Button, DateContainer} from './styles'
import DiagnosticsContainer from './DiagnosticsContainer'
import BenchmarkContainer from './BenchmarkContainer'
import { Spinner } from 'shared/assets/svgs'

const Results = () => {
  const {data: forms, status: formsStatus} = useQuery('results', () => client('org-admin/organization/forms?order=desc&sortBy=publishedDate&answerStatus=completed&excludeProps=questions'))
  
  const today = new Date()
  const options = {year: 'numeric', month: 'long', day: '2-digit', hour:'2-digit', minute:'2-digit' }
  const [selectedTab, setSelectedTab] = useState('diagnostics')
  return (
    <div style={{display: 'flex',width:'80%', justifyContent:'center', flexDirection:'column', margin:'auto'}}>
      {formsStatus === 'loading'
       ? <Spinner />
       : <>
          <Heading
          >
            Mis resultados
          </Heading>
          <div style={{display:'flex', justifyContent:'center', borderBottom: '1px solid gray'}}>
            <Button selected={selectedTab === 'diagnostics'} onClick={()=> setSelectedTab('diagnostics')}>Autodiagnóstico</Button>
            <Button selected={selectedTab === 'benchmark'} onClick={()=> setSelectedTab('benchmark')}>Benchmark</Button>
          </div>
          <DateContainer>
            {today.toLocaleDateString('es-LA', options)} hs
          </DateContainer>
          {selectedTab === 'diagnostics' ? <DiagnosticsContainer formulary={forms} formsStatus={formsStatus} /> : <BenchmarkContainer formularies={forms} formsStatus={formsStatus} />}
         </>
      }
    </div>
  )
}

export {Results}