import React, {useState} from 'react'
import {StyledHeader, Text} from './styles'
import styled from 'styled-components'
// import {ListItem} from '@Winguweb/react-component-library'
import {User} from 'shared/assets/svgs/index'
import {Dropdown} from '../Dropdown'
import {DROPDOWN_TYPES} from 'types/dropdown-types'

const options = [
  {text: 'Mi cuenta', icon: <User />, route: '/perfil' }
]

const Header = ({ organization, setToggleDropdown, toggleDropdown}) => {
  const firstCharacter = organization?.name?.charAt(0)
  return (
    <StyledHeader>
      <Text>
        Te damos la bienvenida, {organization?.name}
      </Text>
      <div>
        <ListItem 
          fontSize='sm' 
          bg='transparent'
          style={{display: 'unset'}}
          onClick={() => setToggleDropdown('profile')}
        >
          <Profile>
            {firstCharacter.toUpperCase()}
          </Profile>
          {toggleDropdown === DROPDOWN_TYPES.profile
            ? <Dropdown 
                options={options}
                position='left'
              />
            : null
          }
        </ListItem>
      </div>
    </StyledHeader>
  )
}

const ListItem = styled.a`
  position: relative;
  font-size: ${props => props.theme.fontSizes.customBase};
  background: transparent;
  display: unset;

  text-decoration: none;
  cursor: pointer;
`

const Profile = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  font-size: 18px;
  color: #411968;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
`

export {Header}